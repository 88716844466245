import React, { useEffect, useState } from 'react';

const topShadow = {
  '--tw-shadow': '0px -4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
  'boxShadow': 'var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)'
};

const LOCAL_STORAGE_KEY = 'did-acknowledge-cookies';

function CookieBanner () {
  const [acknowledged, setAcknowledged] = useState(false);

  useEffect(() => {
    const value = localStorage.getItem(LOCAL_STORAGE_KEY);
    setAcknowledged(value === 'true');
  }, []);

  const onClick = () => {
    localStorage.setItem(LOCAL_STORAGE_KEY, 'true');
    setAcknowledged(true);
  };

  const height = acknowledged ? 'h-0' : 'h-36'

  return (
    <div className={`fixed ${height} bottom-0 w-screen z-50 bg-white transition-height duration-500 ease-in-out`} style={topShadow}>
      <div className="w-full flex flex-col items-center text-gray-600 pt-4">
        <h1 className="font-bold">This website uses cookies</h1>
        <p className="text-sm">We use cookies to recognise our users and monitor website performance.</p>
        <p className="text-sm font-bold">Cookies are not use cookies for advertising purposes or sent to any 3rd party.</p>
        <button onClick={onClick} className="mt-2 rounded-lg bg-blue-500 hover:bg-blue-600 text-white py-2 px-2">Dismiss</button>
      </div>
    </div>
  );
}

export default CookieBanner;