import React from 'react';

function Features() {
  return (
    <section className="text-gray-600 body-font">
  <div className="container px-5 py-24 mx-auto">
    <div className="text-center mb-20">
      <h1 className="sm:text-3xl text-2xl font-medium title-font text-gray-900 mb-10">We have tailored this platform for our transaction specifically</h1>
      <p className="text-base leading-relaxed lg:w-3/5 mx-auto text-gray-500s">Leading the way starts with a single step. A measurable achievement in the right direction. We understand that this transaction will evolve over time, and that is why we are starting with the exchange of documents, enabling the local authority to acquire housing and confirm offers from private developers, through a simple, efficient and transparent digital process.</p>
      <p className="mt-4 text-base leading-relaxed lg:w-3/5 mx-auto text-gray-500s">Using this technology will be a powerful asset for our government in the fight against our national housing crisis.</p>
      <div className="flex mt-6 justify-center">
        <div className="w-16 h-1 rounded-full bg-blue-500 inline-flex"></div>
      </div>
    </div>
    <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
      <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
        <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-blue-100 text-blue-500 mb-5 flex-shrink-0">
          <svg className="w-10 h-10" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" /></svg>
        </div>
        <div className="flex-grow">
          <h2 className="text-gray-900 text-lg title-font font-medium mb-3">Prop Solicitor</h2>
          <p className="leading-relaxed text-base mb-3">The Vendors legal representative will upload the final legal documents for review. </p>
          <a className="leading-relaxed text-base underline text-blue-500 cursor-pointer" href="https://test-louth-site.s3-eu-west-1.amazonaws.com/How+it+works.pdf" target="_blank" rel="noopener noreferrer">How it works for the Vendor’s Legal Representative</a>
          {/* <button className="mt-3 text-blue-500 inline-flex items-center">Learn More
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </button> */}
        </div>
      </div>
      <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
        <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-blue-100 text-blue-500 mb-5 flex-shrink-0">
        <svg className="w-10 h-10" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10" /></svg>
        </div>
        <div className="flex-grow">
          <h2 className="text-gray-900 text-lg title-font font-medium mb-3">Local Authority Solicitor</h2>
          <p className="leading-relaxed text-base mb-3">Local authority legal representatives will download, review and verify the legal documents. If no queries arise, they will invite their client to make an offer.</p>
          <a className="leading-relaxed text-base underline text-blue-500 cursor-pointer" href="https://test-louth-site.s3-eu-west-1.amazonaws.com/How+it+works.pdf" target="_blank" rel="noopener noreferrer">How it works for the Purchaser’s Legal Representative</a>
          {/* <button className="mt-3 text-blue-500 inline-flex items-center">Learn More
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </button> */}
        </div>
      </div>
      <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
        <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-blue-100 text-blue-500 mb-5 flex-shrink-0">
        <svg className="w-10 h-10" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" /></svg>
        </div>
        <div className="flex-grow">
          <h2 className="text-gray-900 text-lg title-font font-medium mb-3">Louth County Council</h2>
          <p className="leading-relaxed text-base mb-3">The local authority, once they are satisfied with the specifications of the portfolio, the details of the planning and legal documents, they will make an offer subject to contract/contract denied.</p>
          <a className="leading-relaxed text-base underline text-blue-500 cursor-pointer" href="https://test-louth-site.s3-eu-west-1.amazonaws.com/How+it+works.pdf" target="_blank" rel="noopener noreferrer">How it works for the Local Authority</a>

          {/* <button className="mt-3 text-blue-500 inline-flex items-center">Learn More
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </button> */}
        </div>
      </div>
    </div>
    {/* <button className="flex mx-auto mt-16 text-white bg-blue-500 border-0 py-2 px-8 focus:outline-none hover:bg-blue-600 rounded text-lg">Button</button> */}
  </div>
</section>
  );
}

export default Features;