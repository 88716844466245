import React from 'react';

const STATES = ['START', 'DOCUMENTS_APPROVED', 'BID_MADE', 'BID_ACCEPTED'];


function ProgressIndicator({ state, bidSectionRef, documentSectionRef }) {
  const nextCircle = 'h-12 w-12 border-2 border-blue-500 rounded-full text-blue-500 flex items-center justify-center';
  const completedCircle = 'h-12 w-12 rounded-full text-white flex items-center justify-center bg-blue-500';
  const notAvailableCircle = 'h-12 w-12 border-2 bg-gray-300 border-gray-400 rounded-full text-gray-500 flex items-center justify-center';

  const currentStep = STATES.findIndex((i) => i === state);
  
  const getClassForCircle = (step) => {
    if(step === currentStep) return nextCircle;
    if(step < currentStep) return completedCircle;
    if(step > currentStep) return notAvailableCircle;
  }

  const getClassForVerticalText = (step) => {
    if(step <= currentStep) return 'ml-1 text-blue-500';
    return 'ml-1';
  }

  const getClassForHorizontalText = (step) => {
    if(step <= currentStep) return 'mt-1 text-blue-500';
    return 'mt-1';
  }

  const getClassForVerticalLine = (toStep) => {
    if(currentStep >= toStep) return 'h-12 w-0.5 bg-blue-500';
    return 'h-12 w-0.5 bg-gray-400';
  }

  const getClassForHorizontalLine = (toStep) => {
    if(currentStep >= toStep) return 'h-0.5 w-24 bg-blue-400';
    return 'h-0.5 w-24 bg-gray-400';
  };

  const takeToNextStep = () => {
    if(currentStep === 0) {
      documentSectionRef.current.scrollIntoView({ behavior: "smooth", block: 'center' });
      console.log('scrolling to docs');
    } else {
      bidSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const allCompleted = currentStep === STATES.length - 1;

  return (
    <div>
      <div className="block md:hidden w-full bg-gray-100 rounded-lg mt-4 flex flex-col items-center justify-center">
        <div className="flex flex-col">
            <div className="flex flex-row items-center h-24">
              <div className="flex flex-col items-center justify-space">
                <div className="invisible h-12 w-0.5"></div>
                <div className={getClassForCircle(0)}>1</div>
                <div className={getClassForVerticalLine(1)}></div>
              </div>
              <p className={getClassForVerticalText(0)}>Approve Legal Documents</p>
            </div>

            <div className="flex flex-row items-center h-24">
              <div className="flex flex-col items-center justify-space">
                <div className={getClassForVerticalLine(1)}></div>
                <div className={getClassForCircle(1)}>2</div>
                <div className={getClassForVerticalLine(2)}></div>
              </div>
              <p className={getClassForVerticalText(1)}>Offer</p>
            </div>

            <div className="flex flex-row items-center h-24">
              <div className="flex flex-col items-center justify-space">
                <div className={getClassForVerticalLine(2)}></div>
                <div className={getClassForCircle(2)}>3</div>
                <div className="invisible h-12 w-0.5"></div>
              </div>
              <p className={getClassForVerticalText(2)}>Accept</p>
            </div>

            {
              !allCompleted && 
              <div className="w-full flex flex-col justify-center items-center">
                <button className="w-56 flex flex-row bg-blue-500 rounded-lg px-5 py-3 text-white mb-4 hover:bg-blue-600">
                  Take me to next step
                  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 17l-4 4m0 0l-4-4m4 4V3" /></svg>
                </button> 
               </div>
            }

            {
              allCompleted &&
              <div className="w-full bg-gray-100 text-center rounded-lg flex flex-row items-center justify-center pb-5">
                <svg className="w-6 h-6 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" /></svg>
                <p className="text ml-4">All steps are complete.</p>
              </div>
            }
        </div>
      </div>

      <div className="hidden md:block w-full bg-gray-100 rounded-lg mt-4 flex flex-col items-center justify-center">
        <div className="flex flex-row w-full justify-center px-16 py-8">
          <div className="flex flex-col items-center w-48">
            <div className="flex flex-row items-center justify-space">
              <div className="invisible h-0.5 w-24"></div>
              <div className={getClassForCircle(0)}>1</div>
              <div className={getClassForHorizontalLine(1)}></div>
            </div>
            <p className={getClassForHorizontalText(0)}>Approve Legal Documents</p>
          </div>

          <div className="flex flex-col items-center w-48">
            <div className="flex flex-row items-center">
              <div className={getClassForHorizontalLine(1)}></div>
              <div className={getClassForCircle(1)}>2</div>
              <div className={getClassForHorizontalLine(2)}></div>
            </div>
            <p className={getClassForHorizontalText(1)}>Offer</p>
          </div>

          <div className="flex flex-col items-center w-48">
            <div className="flex flex-row items-center">
              <div className={getClassForHorizontalLine(2)}></div>
              <div className={getClassForCircle(2)}>3</div>
              <div className="invisible h-1 w-24"></div>
            </div>
            <p className={getClassForHorizontalText(2)}>Accept</p>
          </div>
        </div>

        {
          !allCompleted &&
          <div className="w-full flex flex-col justify-center items-center">
            <button className="w-56 flex flex-row bg-blue-500 rounded-lg px-5 py-3 text-white mb-4 hover:bg-blue-600" onClick={takeToNextStep}>
              Take me to next step
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 17l-4 4m0 0l-4-4m4 4V3" /></svg>
            </button> 
          </div>
        }

        {
          allCompleted &&
          <div className="w-full bg-gray-100 text-center rounded-lg flex flex-row items-center justify-center pb-5">
            <svg className="w-6 h-6 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" /></svg>
            <p className="text ml-4">All steps are complete.</p>
          </div>
        }
      </div>
    </div>
  );
}

export default ProgressIndicator;