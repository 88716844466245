import React from 'react';
import { DualRing } from 'react-css-spinners';


const getDefaultColourClass = (colour) => {
  switch(colour) {
    case 'blue':
      return 'text-white bg-blue-500 hover:bg-blue-600 cursor-pointer';
    case 'red':
      return 'text-white bg-red-500 hover:bg-red-600 cursor-pointer';
    case 'green':
      return 'text-white bg-green-500 hover:bg-green-600 cursor-pointer';
    case 'gray':
      return 'text-white bg-gray-600 hover:bg-gray-700 cursor-pointer';
    default:
      throw new Error(`Unsupported button colour: ${colour}`)
  }
}

const getDisabledColourClass = (colour) => {
  switch(colour) {
    case 'blue':
      return 'text-white bg-blue-200 cursor-default';
    case 'red':
      return 'text-white bg-red-200 cursor-default';
    case 'green':
      return 'text-white bg-green-200 cursor-default';
    case 'gray':
      return 'text-white bg-gray-200  cursor-default';
    default:
      throw new Error(`Unsupported button colour: ${colour}`)
  }
};

const getLoadingColourClass = (colour) => {
  switch(colour) {
    case 'blue':
      return 'text-white bg-blue-600 cursor-default';
    case 'red':
      return 'text-white bg-red-500 cursor-default';
    case 'green':
      return 'text-white bg-green-500 cursor-default';
    case 'gray':
      return 'text-white bg-gray-700 cursor-default';
    default:
      throw new Error(`Unsupported button colour: ${colour}`)
  }
}

function getColourClass({ disabled, isLoading, colour }) {
  if(disabled) {
    return getDisabledColourClass(colour);
  }
  if(isLoading) {
    return getLoadingColourClass(colour);
  }
  return getDefaultColourClass(colour);
}


function Button({ children, type='button', colour='blue', className='', isLoading, onClick, disabled }) {
  const colourClass = getColourClass({ disabled, isLoading, colour });
  const content = isLoading ? <DualRing color="#FFFFFF" size={40} className="button-loader"></DualRing> : children;

  const localOnClick = (e) => {
    if(isLoading) {
      e.preventDefault();
      return;
    }
    if(onClick) {
      onClick();
    }
  };

  return (
    <button type={type} onClick={localOnClick} className={`${className} ${colourClass} h-12 border-0 focus:outline-none rounded text-lg flex flex-col items-center justify-center`}>{content}</button>
  );
}

export default Button;