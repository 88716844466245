import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import Auth from '../domain/Auth';
import Role from '../domain/Role';

const inputClassName = 'w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out';

const readOnlyInputClassName = 'w-full bg-gray-100 rounded border border-gray-300 text-base outline-none text-gray-700 py-1 px-3 leading-8';

const getRoleDisplayName = (role) => {
  switch(role) {
    case Role.SIGNATORY:
      return 'Signatory';
    case Role.GUEST:
      return 'Guest';
    case Role.PROP_ADMIN:
      return 'Admin';
    case Role.PROP_SOLICITOR:
      return 'Prop Solicitor';
    case Role.EXTERNAL_SOLICITOR:
      return 'Solicitor';
    default:
      throw new Error(`Invalid role: ${role}`);
  }
};

function SignUpPage() {
  const { token } = useParams();
  const [errorMessage, setErrorMessage] = useState(token ? '' : 'Invalid invite link');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [accepted, setAccepted] = useState(false);
  const history = useHistory();
  const user = useUser();

  const auth = useMemo(() => new Auth(), []);

  useEffect(() => {
    if(user) {
      history.push('/portfolio');
      return;
    }
    (async () => {
      const [err, data] = await auth.getTokenDetails(token);
      if(err) {
        setErrorMessage(err);
        return;
      }
      setEmail(data.email);
      setRole(getRoleDisplayName(data.role));
    })();
  }, [history, user, auth, token]);

  const validate = () => {
    if(!name) {
      setErrorMessage('Name cannot be empty');
      return false;
    }
    if(!password) {
      setErrorMessage('Password cannot be empty');
      return false;
    }
    return true;
  }

  const createAccount = async () => {
    setErrorMessage('');
    const [err] = await auth.acceptInvite(token, name, password);
    if(err) {
      return setErrorMessage(err);
    }
    history.push('/signin?message=Account+successfully+created.+Please+sign+in.');
  };

  const allRequirements = accepted && name && password;
  const createButtonStyle = allRequirements ? 'bg-blue-500 hover:bg-blue-700 cursor-pointer' : 'bg-blue-600 opacity-50 cursor-default';

  const onSubmit = (e) => {
    e.preventDefault();
    if(!allRequirements) return;
    const ok = validate();
    if(ok) {
      createAccount();
    }
  };

  const checkboxStyle = accepted ? '' : 'hidden';
  const toggleAccepted = () => setAccepted(accepted => !accepted);
  
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 w-full 2xl:w-2/3 mx-auto shadow-md rounded-2xl mt-16 mb-32 md:mb-0">
      
      <div className="h-full bg-blue-500 rounded-t-2xl md:rounded-none md:rounded-l-2xl flex flex-col items-center">
        <svg xmlns="http://www.w3.org/2000/svg" stroke="none" fill="currentColor" className="text-white h-40 mt-8" viewBox="0 0 200 200">
          <path d="M100,40.24,67.47,70.73a1.63,1.63,0,0,1-1.13.45H47.26a1.66,1.66,0,0,1-1.16-2.84l52.74-52a1.64,1.64,0,0,1,2.32,0l52.74,52a1.66,1.66,0,0,1-1.16,2.84H133.66a1.63,1.63,0,0,1-1.13-.45L100,40.24"/>
          <path d="M154,95.37a57.31,57.31,0,0,0-22.21-21l-2.92-1.58a.83.83,0,0,0-1.22.73v27.68a1.81,1.81,0,0,0,.38,1.1,34.09,34.09,0,0,1,2.87,4.32,37.88,37.88,0,0,1,4.62,18.8,37.89,37.89,0,0,1-4.62,18.81,33.11,33.11,0,0,1-12.73,12.88A36,36,0,0,1,100,161.74a36,36,0,0,1-18.15-4.62,33.11,33.11,0,0,1-12.73-12.88,37.89,37.89,0,0,1-4.62-18.81,37.88,37.88,0,0,1,4.62-18.8A35,35,0,0,1,72.2,102a.79.79,0,0,0,.17-.49V73.62a.83.83,0,0,0-1.18-.75c-1,.47-2,1-3,1.51a56.5,56.5,0,0,0-22.21,21,57.37,57.37,0,0,0-8,30.06,57.38,57.38,0,0,0,8,30.07,56.5,56.5,0,0,0,22.21,21A66.19,66.19,0,0,0,100,184.11a65.74,65.74,0,0,0,31.77-7.62A58.68,58.68,0,0,0,154,95.37Z"/>
          <path d="M121.53,70a72.59,72.59,0,0,0-43,0,.84.84,0,0,0-.58.8V94.75a.82.82,0,0,0,1.28.68c.86-.59,1.74-1.16,2.67-1.68,3.8-2.17,9.66-3.56,14.1-4.2V79.14h8.1V89.55c4.44.64,10.3,2,14.1,4.2.93.52,1.81,1.09,2.67,1.68a.82.82,0,0,0,1.28-.68V70.83A.84.84,0,0,0,121.53,70Z"/>
        </svg>

        <h1 className="text-3xl title-font text-white text-center mt-4 pb-12 md:pb-0">Welcome to Prop</h1>

        {/* <div className="mt-8 text-white">
          <p>Helper text goes here</p>
          <p>Helper text goes here</p>
          <p>Helper text goes here</p>
        </div> */}

      </div>

      <form className="bg-white rounded-b-2xl md:rounded-none md:rounded-r-2xl flex flex-col pt-12 pb-16 px-8 justify-center" onSubmit={onSubmit}>
        <h1 className="text-2xl mb-4">Sign Up</h1>

        {errorMessage && <p className="mb-2 text-red-400 font-bold">{errorMessage}</p>}
        
        <div className="mb-2">
          <label htmlFor="role" className="leading-7 text-sm text-gray-600">Role</label>
          <input type="text" id="role" name="role" className={readOnlyInputClassName} value={role} readOnly/>
        </div>

        <div className="mb-2">
          <label htmlFor="email" className="leading-7 text-sm text-gray-600">Email</label>
          <input type="text" id="email" name="email" className={readOnlyInputClassName} value={email} readOnly/>
        </div>

        <div className="mb-2">
          <label fhtmlForor="name" className="leading-7 text-sm text-gray-600">Name</label>
          <input type="text" id="name" name="name" className={inputClassName} value={name} onChange={e => setName(e.target.value)}/>
        </div>

        <div className="mb-2">
          <label htmlFor="password" className="leading-7 text-sm text-gray-600">Password</label>
          <input type="password" id="password" name="password" className={inputClassName} value={password} onChange={e => setPassword(e.target.value)}/>
        </div>

        <div className="flex flow-row justify-center mt-4">
          <div class="bg-white border-2 rounded border-gray-400 w-4 h-4 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500 mt-1" onClick={toggleAccepted}>
            <input type="checkbox" class="opacity-0 absolute"/>
            <svg class={`fill-current w-2 h-2 text-green-500 pointer-events-none ${checkboxStyle}`} viewBox="0 0 20 20"><path d="M0 11l2-2 5 5L18 3l2 2L7 18z"/></svg>
          </div>
          <div className="ml-4 text-sm text-left">
            <p>I agree to the <span className="text-blue-500 cursor-pointer">Terms & Conditions</span>. Your details will be used in accordance with our <span className="text-blue-500 cursor-pointer">Privacy Policy</span>.</p>
          </div>
        </div>

        <button type="submit" className={`border-0 py-2 px-6 focus:outline-none text-white rounded text-lg mt-6 ${createButtonStyle}`}>Create Account</button>
      </form>
    </div>
  );
}

export default SignUpPage;
