import { nanoid } from 'nanoid';
import Queue from '../util/Queue';
import FileUploadState from './FileUploadState';
import * as R from 'ramda';
import parseFileName from './parseFileName';

class Api {
  constructor({ token, role, onAuthFail }) {
    this.url = `${process.env.REACT_APP_URL}`;
    this.token = token;
    this.role = role;
    this.onAuthFail = onAuthFail;
  }

  getRole() {
    return this.role;
  }

  async makeRequest(endpoint, body = {}) {
    const res = await fetch(`${this.url}${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.token
      },
      body: JSON.stringify(body)
    });
    const resBody = await res.json();
    if(res.ok) {
      return resBody;
    }
    if(res.status === 404) {
      return null;
    }
    if(res.status === 401) {
      return this.onAuthFail();
    }
    throw new Error(resBody.message);
  }

  async listUsers() {
    return this.makeRequest('/v1/ListUsers');
  }

  async inviteUser(email, role) {
    return this.makeRequest('/v1/InviteUser', { email, role });
  }

  async deleteUser(email) {
    return this.makeRequest('/v1/DeleteUser', { email });
  }

  async listInvites() {
    return this.makeRequest('/v1/ListInvites');
  }

  async deleteInvite(email) {
    return this.makeRequest('/v1/DeleteInvite', { email });
  }

  async listDevelopments() {
    return this.makeRequest('/v1/ListDevelopments');
  }

  async getDevelopment(id) {
    return this.makeRequest('/v1/GetDevelopment', { id });
  }

  async getDocumentViewUrl(id) {
    return this.makeRequest('/v1/GetDocumentViewUrl', { id });
  }

  async getBundleViewUrl(folder, subFolder) {
    return this.makeRequest('/v1/GetBundleViewUrl', { folder, subFolder });
  }

  async listFolders() {
    return this.makeRequest('/v1/ListFolders');
  }

  async listDocuments(folder, subFolder) {
    return this.makeRequest('/v1/ListDocuments', { folder, subFolder: subFolder || undefined });
  }

  async deleteDocument(id) {
    return this.makeRequest('/v1/DeleteDocument', { id });
  }

  /**
   * @private
   */
  async getUploadUrls(uploads) {
    return this.makeRequest('/v1/GetUploadUrls', { uploads });
  }

  /**
   * @private
   */
  async confirmUploads(ids) {
    return this.makeRequest('/v1/ConfirmUploads', { ids });
  }

  async uploadDocuments(files, folder, subFolder, statusCallback) {
    const uploads = files.map((f) => ({
      id: nanoid(),
      name: parseFileName(f.name).name,
      type: parseFileName(f.name).ext.toLowerCase(),
      folder,
      subFolder,
      file: f
    }));
    
    const { urls } = await this.getUploadUrls(uploads.map(u => R.omit(['file'], u)));
    const queue = new Queue(uploads.map((u, i) => ({ ...u, url: urls[i] })));

    const successfulIds = [];
    while(!queue.isEmpty()) {
      const next = queue.dequeue();
      statusCallback({ name: next.file.name, state: FileUploadState.START });
      console.log(next.file);
      const fileUploadRes = await fetch(next.url, { method: 'PUT', body: next.file });
      if(fileUploadRes.ok) {
        successfulIds.push(next.id);
        statusCallback({ name: next.file.name, state: FileUploadState.SUCCESS });
      } else {
        statusCallback({ name: next.file.name, state: FileUploadState.FAILURE });
      }
    }

    await this.confirmUploads(successfulIds);
  }

  async approveDocuments(developmentId) {
    return this.makeRequest('/v1/ApproveDocuments', { id: developmentId });
  }

  async makeOffer(developmentId, amount) {
    return this.makeRequest('/v1/MakeOffer', { id: developmentId, amount });
  }

  async acceptOffer(developmentId) {
    return this.makeRequest('/v1/AcceptOffer', { id: developmentId });
  }

  async resetDevelopment(developmentId) {
    return this.makeRequest('/v1/ResetDevelopment', { id: developmentId });
  }
}

export default Api;