import React, { useState } from 'react';
import Modal from 'react-modal';
import Role from '../domain/Role';
import DevelopmentState from '../domain/DevelopmentState';

const modalStyle = {
  content : {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

const DefaultApprovalSection = ({ development }) => {
  if(development.state === DevelopmentState.START) {
    return (
      <div className="w-full bg-gray-100 text-center rounded-lg my-5 flex flex-row items-center justify-center p-5">
        <svg className="w-6 h-6 text-red-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
        <p className="text ml-4">The documents have not yet been approved.</p>
      </div>
    ); 
  }
  return (
    <div className="w-full bg-gray-100 text-center rounded-lg my-5 flex flex-row items-center justify-center p-5">
      <svg className="w-6 h-6 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" /></svg>
      <p className="text ml-4">All documents have been approved.</p>
    </div>
  );
}

const ExternalSolicitorApprovalSection = ({ development, api, onModify }) => {
  const [approveClicked, setApproveClicked] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const cancel = () => {
    setErrorMessage('');
    setApproveClicked(false);
  };
  
  const approve = async () => {
    setErrorMessage('');
    try {
      await api.approveDocuments(development.id);
      setApproveClicked(false);
      onModify();
    } catch (e) {
      setErrorMessage(e.message);
    }
  };

  if(development.state === DevelopmentState.START) {
    return (
      <div>
        <div className="w-full bg-gray-100 text-center rounded-lg mt-4">
          <button type="button" onClick={setApproveClicked} className="my-5 text-white bg-blue-500 border-0 py-2 px-6 focus:outline-none hover:bg-blue-600 rounded text-lg">Approve Documents</button>
        </div>
        <Modal isOpen={approveClicked} style={modalStyle}>
          <div className="container flex flex-col items-center justify-center w-96">
            <h1 className="text-2xl font-medium title-font">Approve Documents</h1>
            <div className="mt-4 w-full px-6 text-center text-gray-500 font-light">
              <p>I approve the documents as they currently appear. I understand that this will allow my clients to bid on this portfolio item.</p>
            </div>

            {errorMessage && <p className="text-red-400 mt-2">{errorMessage}</p>}

            <div className="w-full flex justify-around mt-4">
              <button className="w-full h-12 text-lg bg-gray-600 rounded text-white hover:bg-gray-700 mx-2" onClick={cancel}>Cancel</button>
              <button className="w-full h-12 text-lg bg-blue-600 rounded text-white hover:bg-blue-700 mx-2" onClick={approve}>Confirm</button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }

  return (
    <div className="w-full bg-gray-100 text-center rounded-lg my-5 flex flex-row items-center justify-center p-5">
      <svg className="w-6 h-6 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" /></svg>
      <p className="text ml-4">All documents have been approved.</p>
    </div>
  );
};

const PropSolicitorApprovalSection = ({ api, development }) => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const onEmailChange = (e) => setEmail(e.target.value);

  const invite = async () => {
    setErrorMessage('');
    setSuccessMessage('');
    const [err] = await api.inviteUser(email, Role.EXTERNAL_SOLICITOR);
    if(err) {
      return setErrorMessage(err);
    }
    setSuccessMessage('Invitation has been sent.');
  };

  return (
    <div>
      <div className="w-full bg-gray-100 text-center rounded-lg my-6 p-5 flex flex-col items-center">
        <h2 className="text-lg font-bold">Invite Solicitor</h2>
        <p className="mt-2">Invite a solicitor that represents the signatories.</p>
        <input type="text" className="w-full sm:w-1/2 md:1/3 border border-gray-300 rounded-lg mt-2 h-8 text-center" placeholder="solicitor@example.com" onChange={onEmailChange} value={email}></input>
        {successMessage && <p className="text-green-500 mt-2">{successMessage}</p>}
        {errorMessage && <p className="text-red-400 mt-2">{errorMessage}</p>}
        <button type="button" onClick={invite} className="text-white bg-blue-500 border-0 py-2 px-6 focus:outline-none hover:bg-blue-600 rounded text-lg mt-2">Invite</button>
      </div>

      <DefaultApprovalSection development={development} />
    </div>
  );
}

const SignatoryApprovalSection = ({ development }) => {
  if(development.state === DevelopmentState.START) {
    return (
      <div className="w-full bg-gray-100 text-center rounded-lg my-5 flex flex-row items-center justify-center p-5">
        <svg className="w-6 h-6 text-red-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
        <p className="text ml-4">The documents have not yet been approved by your solicitor.</p>
      </div>
    );
  }
  return (
    <div className="w-full bg-gray-100 text-center rounded-lg my-5 flex flex-row items-center justify-center p-5">
      <svg className="w-6 h-6 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" /></svg>
      <p className="text ml-4">All documents have been approved by your solicitor.</p>
    </div>
  );
}

const ApprovalSection = ({ development, api, onModify }) => {
  switch(api.getRole()) {
    case Role.EXTERNAL_SOLICITOR:
      return <ExternalSolicitorApprovalSection development={development} api={api} onModify={onModify} />;
    case Role.PROP_SOLICITOR:
      return <PropSolicitorApprovalSection api={api} development={development} />
    case Role.SIGNATORY:
      return <SignatoryApprovalSection development={development} />
    default:
      return <DefaultApprovalSection development={development} />
  }
};

export default ApprovalSection;