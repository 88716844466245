import { useEffect, useState } from 'react';
import PdfIcon from './icons/Pdf';
import ZipIcon from './icons/Zip';
import GeneralDocumentIcon from './icons/GeneralDocument';
import { Collapse } from 'react-collapse';
import Modal from 'react-modal';
import DocumentIcon from './icons/Document';
import FileUploadState from '../domain/FileUploadState';
import Clock from './icons/Clock';
import TickCircle from './icons/TickCircle';
import XCircle from './icons/XCircle';
import Button from './util/Button';
import DotsVerticalIcon from './icons/DotsVertical';
import Menu from './util/Menu';
import Role from '../domain/Role';
import parseFileName from '../domain/parseFileName';
import ApprovalSection from './DocumentApproval';

const NEW_FOLDER = '__NEW_FOLDER__';
const NONE_FOLDER = '__NONE_FOLDER__';

const modalStyle = {
  content : {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

function getSizeText(size) {
  if (size > 1000000) {
    const numbers = size / 1000000;
    return `${Number(numbers).toFixed(1)} MB`;
  } else {
    const numbers = Math.floor(size / 1000);
    return `${numbers} KB`;
  }
}

function getDocumentIcon({ type }) {
  switch(type) {
    case 'pdf':
      return <PdfIcon className="w-16 h-16 cursor-pointer" />;
    default:
      return <GeneralDocumentIcon className="w-16 h-16 cursor-pointer" />;
  }
}

function canWriteDocument(api, folder) {
  const role = api.getRole();
  if(role === Role.PROP_ADMIN) {
    return true;
  }
  if(role === Role.PROP_SOLICITOR) {
    return folder.toLowerCase().startsWith('legal');
  }
  return false;
}

function Document({ api, document, onModify }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [deleteClicked, setDeleteClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');

  const reset = () => {
    setIsMenuOpen(false);
    setDeleteClicked(false);
    setIsLoading(false);
  }

  const deleteDoc = async () => {
    try {
      setIsLoading(true);
      await api.deleteDocument(document.id);
      onModify();
      reset();
    } catch (e) {
      setIsLoading(false);
      setMessage(e.message);
    }
  };

  const viewDoc = async () => {
    const { url } = await api.getDocumentViewUrl(document.id);
    window.open(url, '_blank', 'noopener');
  }

  const canWrite = canWriteDocument(api, document.folder);
  const invisible = canWrite ? '' : 'invisible';

  return (
    <div className="w-full h-56 rounded-lg border flex flex-col" >
      <div className="flex flex-1 flex-col items-center mt-2">
        <div className="flex flex-col items-end w-full">
          <div className={`border rounded-lg p-0.5 hover:border-gray-400 mr-2 ${invisible}`} onClick={() => setIsMenuOpen(o => !o)}>
            <DotsVerticalIcon className="h-6 w-6 text-gray-400" />
          </div>
          <Menu isOpen={isMenuOpen} setIsOpen={setIsMenuOpen}>
            <div className="mx-2 rounded">
              <button className="py-2 text-sm text-red-700 w-full" role="menuitem" onClick={() => setDeleteClicked(true)}>Delete</button>
            </div>
            <Modal isOpen={deleteClicked} style={modalStyle}>
              <div className="container flex flex-col items-center justify-center w-96">
                <h1 className="text-2xl font-medium title-font">Delete Document</h1>
                <div className="mt-4 w-full px-6">
                  <div className={`$flex flex-col items-center my-5`}>
                    <p className={`block text-gray-400 font-normal px-3`}>Are you sure you want to delete document <span className="font-bold">{document.name}?</span></p>
                  </div>
                  {message && <p className="text-red-400">{message}</p>}
                </div>
                <div className="w-full flex justify-around mt-6">
                  <Button disabled={isLoading} className="w-1/2 mx-2" colour="gray" onClick={reset}>Cancel</Button>
                  <Button isLoading={isLoading} className="w-1/2 mx-2" colour="red" onClick={deleteDoc}>Delete</Button>
                </div>
              </div>
            </Modal>
          </Menu>
        </div>
        <div className="cursor-pointer" onClick={() => viewDoc()}>
          {getDocumentIcon({ type: document.type })}
        </div>
      </div>
      <div className="h-24 border-t px-3 pt-2 flex flex-col">
        <div className="flex flex-row"> 
          <p className="text-gray-600 cursor-pointer" onClick={() => viewDoc()}>{document.name}</p>
        </div>
      </div>
    </div>
  );
}

function Bundle({ api, folder, subFolder }) {
  const downloadAll = async () => {
    const { url } = await api.getBundleViewUrl(folder, subFolder);
    window.open(url);
  };

  return (
    <div className="w-full h-56 rounded-lg border flex flex-col cursor-pointer">
      <div className="flex flex-1 flex-col items-center justify-center" onClick={() => downloadAll()}>
        <ZipIcon className="w-16 h-16"/>
      </div>
      <div className="h-24 border-t px-3 pt-2 flex flex-col">
        <div className="flex flex-row"> 
          <p className="text-gray-600" onClick={() => downloadAll()}>Download All</p>
        </div>
      </div>
    </div>
  );
}

function DocumentGrid({ api, folder, subFolder, documents, onModify }) {
  return (
    <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4 mt-4">
      {documents.map(d => <Document key={d.id} api={api} document={d} onModify={onModify} />)}
      <Bundle api={api} folder={folder} subFolder={subFolder} />
    </div>
  );
}

function SubFolder({ api, folder, data, documents, onModify }) {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(isOpen => !isOpen);
  const chevronClass = isOpen ? 'rotate-90': 'rotate-0';
  const myDocuments = documents.filter(d => d.subFolder === data.name);
  return (
    <div className="mt-4">
      <div className="flex flex-row items-center cursor pointer max-w-max" onClick={toggleOpen}>
      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 5l7 7-7 7M5 5l7 7-7 7" /></svg>
        <h2 className="ml-2 text-lg">{data.name}</h2>
        <div className="ml-1">
          <svg className={`w-6 h-6 transform duration-300 ${chevronClass} transition-all`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7"/></svg>
        </div>
      </div>
      <Collapse isOpened={isOpen}>
        <DocumentGrid api={api} documents={myDocuments} folder={folder} subFolder={data.name} onModify={onModify} />
      </Collapse>
    </div>
  );
}

function Folder({ api, data, documents, onModify }) {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(isOpen => !isOpen);
  const chevronClass = isOpen ? 'rotate-90': 'rotate-0';
  const myDocuments = documents.filter(d => d.folder === data.name);
  return (
    <div>
      <div className="flex flex-row items-center cursor pointer max-w-max mt-2" onClick={toggleOpen}>
        <h2 className="text-lg">{data.name}</h2>
        <div className="ml-1">
          <svg className={`w-6 h-6 transform duration-300 ${chevronClass} transition-all`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7"/></svg>
        </div>
      </div>
      <Collapse isOpened={isOpen}>
        {data.children && data.children.map(d => <SubFolder key={d.name} api={api} folder={data.name} data={d} documents={myDocuments} onModify={onModify} />)}
        <DocumentGrid api={api} documents={myDocuments.filter(d => !d.subFolder)} folder={data.name} onModify={onModify} />
      </Collapse>
    </div>
  );
}


function SelectFilesField({ files, setFiles }) {
  const onFilesChange = (e) => setFiles(Array.from(e.target.files));
  return (
    <div>
      <label htmlFor='upload-file' className="w-96 cursor-pointer rounded-lg border-2 border-gray-200 bg-white flex flex-col justify-center items-center hover:cursor-pointer text-gray-400 border-dashed">
        <div className="flex flex-col items-center justify-center h-40">
          <DocumentIcon className="w-12 h-12" />
          <span className={`block font-normal`}>{files.length ? 'FILES' : 'Browse files'}</span>
        </div>
        <input id='upload-file' type="file" className="h-0 w-0 opacity-0" onChange={onFilesChange} multiple/>
      </label>
      <div className="flex justify-end items-center text-gray-400 mt-2">
        <span className="flex items-center ">
          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" /></svg>
          secure
        </span>
      </div>
    </div>
  );
}

function SelectedFilesTable({ files }) {
  const formatted = files.map(f => {
    const { name, ext } = parseFileName(f.name);
    return {
      name,
      type: ext,
      size: getSizeText(f.size),
      state: f.state
    };
  });

  const getStateContent = (state) => {
    switch(state) {
      case FileUploadState.START:
        return <Clock className="w-6 h-6 text-gray-500" />;
      case FileUploadState.SUCCESS:
        return <TickCircle className="w-6 h-6 text-green-500" />;
      case FileUploadState.FAILURE:
        return <XCircle className="w-6 h-6 text-red-500" />;
      default:
        return <div></div>
    }
  };

  return (
    <div className="flex flex-col border text-gray-800">
      <div className="flex flex-row border font-bold">
        <div className="w-64 text-center">Name</div>
        <div className="w-16 text-center">Type</div>
        <div className="w-32 text-center">Size</div>
        <div className="w-32 text-center">Status</div>
      </div>
      <div className="flex flex-col text-gray-800">
        {formatted.map(f => (
          <div className="flex flex-row border py-1">
            <div className="w-64 text-center truncate px-2">{f.name}</div>
            <div className="w-16 text-center">{f.type}</div>
            <div className="w-32 text-center">{f.size}</div>
            <div className="w-32 flex flex-col items-center">{getStateContent(f.state)}</div>
          </div>
        ))}
      </div>      
    </div>
  );
}

function FolderSelector({ role, folders = [], setFolder, setSubFolder }) {
  const [selectedFolder, setSelectedFolder] = useState(role === Role.PROP_SOLICITOR ? 'Legal Documents' : NEW_FOLDER);
  const [newFolderName, setNewFolderName] = useState('');
  const [selectedSubFolder, setSelectedSubFolder] = useState(NONE_FOLDER);
  const [newSubFolderName, setNewSubFolderName] = useState('')

  const folderOptions = folders.map(f => f.name);
  const subFolderOptions = folders.find(f => f.name === selectedFolder)
    ? folders.find(f => f.name === selectedFolder).children.map(c => c.name)
    : [];

  useEffect(() => {
    setFolder(role === Role.PROP_SOLICITOR ? 'Legal Documents' : NEW_FOLDER);
    setSubFolder(NONE_FOLDER)
  }, [role, setFolder, setSubFolder]);

  const onFolderSelect = (e) => {
    setSelectedFolder(e.target.value);
    setSelectedSubFolder(NONE_FOLDER);
    if(e.target.value === NEW_FOLDER) {
      return setFolder(newFolderName);
    } 
    setNewFolderName('');
    setFolder(e.target.value);
  }

  const onNewFolderNameChange = (e) => {
    setFolder(e.target.value);
    setNewFolderName(e.target.value);
  }

  const onSubFolderSelect = (e) => {
    setSelectedSubFolder(e.target.value);
    if(e.target.value === NONE_FOLDER) {
      setNewSubFolderName('');
      return setSubFolder(null);
    } else if(e.target.value === NEW_FOLDER) {
      return setSubFolder(newSubFolderName);
    } else {
      setNewSubFolderName('');
      return setSubFolder(e.target.value);
    }
  }

  const onNewSubFolderNameChange = (e) => {
    setSubFolder(e.target.value);
    setNewSubFolderName(e.target.value);
  }

  const folderChoice = role === Role.PROP_SOLICITOR
    ? <option>Legal Documents</option>
    : [<option value={NEW_FOLDER}>New</option>, ...folderOptions.map(f => <option key={f} selected={selectedFolder === f}>{f}</option>)];

  return (
    <div className="w-full flex flex-col items-start mt-6 mb-2">
      <div className="flex flex-row items-center">
        <label className="w-24">Folder</label>
        <div className="relative inline-flex">
          <svg className="w-2 h-2 absolute top-0 right-0 mr-4 mt-3 pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 412 232"><path d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z" fill="#648299" fill-rule="nonzero"/></svg>
          <select className="border border-gray-300 rounded-lg text-gray-600 h-8 pl-2 pr-10 bg-white hover:border-gray-400 focus:outline-none appearance-none w-48" onChange={onFolderSelect}>
            {folderChoice}
          </select>
        </div>
        {
          selectedFolder === NEW_FOLDER &&
          <input 
            type="text"
            className="pl-2 h-8 ml-2 w-72 border border-gray-300 rounded-lg text-gray-600 hover:border-gray-400 focus:outline-none appearance-none"
            onChange={onNewFolderNameChange}
          />
        }
      </div>

      <div className="flex flex-row items-center mt-2">
        <label className="w-24">Subfolder</label>
        <div className="relative inline-flex">
          <svg className="w-2 h-2 absolute top-0 right-0 mr-4 mt-3 pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 412 232"><path d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z" fill="#648299" fill-rule="nonzero"/></svg>
          <select className="border border-gray-300 rounded-lg text-gray-600 h-8 pl-2 pr-10 bg-white hover:border-gray-400 focus:outline-none appearance-none w-48" onChange={onSubFolderSelect}>
            <option value={NEW_FOLDER} selected={selectedSubFolder === NEW_FOLDER}>New</option>
            <option value={NONE_FOLDER} selected={selectedSubFolder === NONE_FOLDER}>None</option>
            {subFolderOptions.map(s => <option key={s} selected={selectedSubFolder === s}>{s}</option>)}
          </select>
        </div>
        {
          selectedSubFolder === NEW_FOLDER &&
          <input
            type="text"
            className="pl-2 h-8 ml-2 w-72 border border-gray-300 rounded-lg text-gray-600 hover:border-gray-400 focus:outline-none appearance-none"
            onChange={onNewSubFolderNameChange}
          />
        }
      </div>
    </div>
  );
}

function UploadButton({ api, onModify, folders }) {
  const [uploadClicked, setUploadClicked] = useState(false);
  const [files, setFiles] = useState([]);
  const [message, setMessage] = useState(null);
  const [folder, setFolder] = useState('');
  const [subFolder, setSubFolder] = useState(null);
  const [loadingState, setLoadingState] = useState('START');

  const reset = () => {
    setUploadClicked(false);
    setFiles([]);
    setMessage(null);
    setLoadingState('START');
  };

  const submit = async () => {
    if(!folder) {
      return setMessage('Folder must have a value.');
    }
    setLoadingState('LOADING');
    try {
      const resolvedSubFolder = subFolder === NONE_FOLDER ? undefined : subFolder;
      await api.uploadDocuments(files, folder, resolvedSubFolder, ({ name, state }) => setFiles(f => {
        return f.map(i => {
          if(i.name === name) {
            i.state = state;
          }
          return i;
        });
      }));
      setLoadingState('LOADED');
      onModify();
    } catch (e) {
      setLoadingState('START');
      setMessage(e.message);
    }
  }

  return (
    <div>
      <button className="bg-blue-500 px-4 py-2 text-white rounded-lg mt-4" onClick={() => setUploadClicked(true)}>Upload</button>
      <Modal isOpen={uploadClicked} style={modalStyle}>
        <div className="w-full h-96 overflow-scroll">
          <div className="container flex flex-col items-center justify-center">
            <h1 className="text-2xl font-medium title-font text-gray-800">Upload Documents</h1>
            <div className="mt-4 w-full px-6">
              {files.length === 0 && <SelectFilesField files={files} setFiles={setFiles} />}
              {files.length > 0 && <SelectedFilesTable files={files} />}
              {files.length > 0 && <FolderSelector role={api.getRole()} folders={folders} setFolder={setFolder} setSubFolder={setSubFolder}/>}
            </div>
            {message && <p className="text-red-400 w-full mt-2 px-5 text-center">{message}</p>}
            <div className="w-full flex justify-around mt-4">
              {loadingState === 'START' && <Button className="w-1/2 mx-2" colour="gray" onClick={reset}>Cancel</Button>}
              {loadingState === 'START' && <Button className="w-1/2 mx-2" colour="green" disabled={files.length === 0} onClick={submit}>Upload</Button>}
              {loadingState !== 'START' && <Button className="w-1/2 mx-2" colour="green" isLoading={loadingState === 'LOADING'} onClick={reset}>Done</Button>}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

function DocumentVault({ development, api, onModify }) {
  const [folders, setFolders] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const [folderRes, documentsRes] = await Promise.all([
        api.listFolders(),
        api.listDocuments()
      ]);
      setFolders(folderRes);
      setDocuments(documentsRes.documents);
      setIsLoading(false);
    })();
  }, [api]);

  const reloadDocuments = async () => {
    const [folderRes, documentsRes] = await Promise.all([
      api.listFolders(),
      api.listDocuments()
    ]);
    setFolders(folderRes);
    setDocuments(documentsRes.documents);
  };

  const canUpload = [Role.PROP_ADMIN, Role.PROP_SOLICITOR].includes(api.getRole());

  return (
    <div>
      {isLoading && <h1>Loading...</h1>}
      {!isLoading && folders.map(f => <Folder key={f.name} api={api} data={f} documents={documents} onModify={reloadDocuments} />)}
      {canUpload && <UploadButton folders={folders} api={api} onModify={reloadDocuments} />}
      <ApprovalSection development={development} api={api} onModify={onModify} />
    </div>
  );
}

export default DocumentVault;