import React from 'react';

const logoStyle = {
  width: '30px'
};


function Footer() {
  return (
    <footer className="text-gray-600 body-font flex-shrink">        
      <div className="bg-gray-100">
        <div className="container px-5 py-6 mx-auto flex items-center flex-row">
          <button className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
            <img style={logoStyle} src="https://static.wixstatic.com/media/080474_b0fb92ff9aff4e83b528acb05ec61ddd~mv2.jpg/v1/fill/w_61,h_83,al_c,q_80,usm_0.66_1.00_0.01/Prop-Marque-Blue.webp" alt="Prop Logo"/>
            <span className="ml-3 text-xl">Prop</span>
          </button>
          <p className="text-sm text-gray-500 ml-6 mt-0">© 2021 Prop</p>
          <span className="inline-flex ml-auto mt-0 justify-start">
            <button className="text-gray-500">
              <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
              </svg>
            </button>
            <button className="ml-3 text-gray-500">
              <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
              </svg>
            </button>
            <button className="ml-3 text-gray-500">
              <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
              </svg>
            </button>
            <button className="ml-3 text-gray-500">
              <svg fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0" className="w-5 h-5" viewBox="0 0 24 24">
                <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
                <circle cx="4" cy="4" r="2" stroke="none"></circle>
              </svg>
            </button>
          </span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;