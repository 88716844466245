import { useEffect, useRef } from 'react';

function Menu({ isOpen, setIsOpen, children }) {
  
  // detects click outside of dropdown and closes it
  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if(dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [dropdownRef, setIsOpen]);

  const changeable = isOpen
    ? `transition ease-out duration-100 transform opacity-100 scale-100`
    : `transition ease-in duration-75 transform opacity-0 scale-95`
  const fullClassName = `-ml-64 origin-top-left absolute mt-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 ${changeable}`;

  return (
    <div className={isOpen ? '' : 'hidden'} ref={dropdownRef}>
      <div className={fullClassName}>
        <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
          {children}
        </div>
      </div>
    </div>
  );
}

export default Menu;