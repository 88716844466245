import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import Auth from '../domain/Auth';

const inputClassName = 'w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out';

function ForgotPasswordPage() {
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [email, setEmail] = useState('');
  const history = useHistory();
  const user = useUser();

  const auth = useMemo(() => new Auth(), []);

  useEffect(() => {
    if(user) {
      history.push('/portfolio');
    }
  });

  const validate = () => {
    if(!email) {
      setErrorMessage('Email is required');
      return false;
    }
    return true;
  }

  const forgotPassword = async () => {
    setMessage('');
    setErrorMessage('');
    const [err] = await auth.forgotPassword(email);
    if(err) {
      return setErrorMessage(err);
    }
    setMessage('Password reset email sent');
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const ok = validate();
    if(ok) {
      forgotPassword();
    }
  };
  
  return (
    <div className="container w-full mx-auto pt-32 flex-grow h-full">
      <form className="bg-white flex flex-col w-96 mx-auto" onSubmit={onSubmit}>
        <h1 className="text-2xl">Request Password Reset</h1>
        {message && <p className="mt-2 text-green-500 font-bold">{message}</p>}
        {errorMessage && <p className="mt-2 text-red-400 font-bold">{errorMessage}</p>}
        <div className="mt-2">
          <label htmlFor="email" className="leading-7 text-sm text-gray-600">Email</label>
          <input type="email" id="email" name="email" className={inputClassName} value={email} onChange={e => setEmail(e.target.value)}/>
        </div>
        <button type="submit" className="mt-4 text-white bg-blue-500 border-0 py-2 px-6 focus:outline-none hover:bg-blue-600 rounded text-lg">Send Reset Email</button>
      </form>
    </div>
  );
}

export default ForgotPasswordPage;
