class Queue {
  constructor(initialState = []) {
    this.state = initialState;
  }

  enqueue(item) {
    this.state.push(item);
  }

  dequeue() {
    return this.state.shift();
  }

  size() {
    return this.state.length;
  }

  isEmpty() {
    return this.size() === 0;
  }
}

export default Queue;