import React, { useState } from 'react';
import Modal from 'react-modal';
import DevelopmentState from '../domain/DevelopmentState';
import Role from '../domain/Role';

const modalStyle = {
  content : {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

const NoOffers = () => (
  <div className="w-full bg-gray-100 text-center rounded-lg flex flex-row items-center justify-center p-5">
    <svg className="w-6 h-6 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
    <p className="text ml-4">No offers have been made.</p>
  </div>
);

const OfferMade = () => (
  <div className="w-full bg-gray-100 text-center rounded-lg flex flex-row items-center justify-center p-5">
    <svg className="w-6 h-6 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
    <p className="text ml-4">An offer has been received, but it has not yet been accepted.</p>
  </div>
);

const OfferAccepted = () => (
  <div className="w-full bg-gray-100 text-center rounded-lg flex flex-row items-center justify-center p-5">
    <svg className="w-6 h-6 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" /></svg>
    <p className="text ml-4">Offer has been accepted.</p>
  </div>
);


const DefaultOfferSection = ({ state }) => {
  switch(state) {
    case DevelopmentState.OFFER_MADE:
      return <OfferMade />;
    case DevelopmentState.OFFER_ACCEPTED:
      return <OfferAccepted />
    default:
      return <NoOffers />;
  }
};

function formatPrice(price) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'EUR'
  });
  return formatter.format(price / 100);
};

const AcceptOffer = ({ api, development, onModify }) => {
  const [errorMessage, setErrorMessage] = useState('');

  const acceptOffer = async () => {
    try {
      setErrorMessage('');
      await api.acceptOffer(development.id);
      onModify();
    } catch (e) {
      setErrorMessage(e.message);
    }
  };

  const formattedAmount = formatPrice(development.price);

  return (
    <div className="w-full bg-gray-100 text-center rounded-lg my-6 p-5 flex flex-col items-center">
      <h2 className="text-lg font-bold">Accept Offer</h2>
      <p className="mt-2">An offer has been received for this development.</p>
      <input type="text" className="w-full sm:w-1/2 md:1/3 border border-gray-300 rounded-lg mt-2 h-8 text-center" value={formattedAmount} readOnly></input>
      {errorMessage && <p className="text-red-400 mt-2">{errorMessage}</p>}
      <button type="button" className="text-white bg-blue-500 border-0 py-2 px-6 focus:outline-none hover:bg-blue-600 rounded text-lg mt-4" onClick={acceptOffer}>Accept</button>
    </div>
  );
}

const PropAdminOfferSection = ({ development, api, onModify }) => {
  switch(development.state) {
    case DevelopmentState.OFFER_MADE:
      return <AcceptOffer development={development} api={api} onModify={onModify} />
    case DevelopmentState.OFFER_ACCEPTED:
      return <OfferAccepted />
    default:
      return <NoOffers />;
  }
};

const InviteSignatories = ({ api }) => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const invite = async () => {
    setErrorMessage('');
    setSuccessMessage('');
    const [err] = await api.inviteUser(email, Role.SIGNATORY);
    if(err) {
      return setErrorMessage(err);
    }
    setSuccessMessage('Invite has been sent.');
  };

  const onEmailChange = (e) => setEmail(e.target.value);

  return (
    <div className="w-full bg-gray-100 text-center rounded-lg my-6 p-5 flex flex-col items-center">
      <h2 className="text-lg font-bold">Invite Signatory</h2>
      <p className="mt-2">Invite a signatory to make an offer on this development.</p>
      <input type="text" className="w-full sm:w-1/2 md:1/3 border border-gray-300 rounded-lg mt-2 h-8 text-center" placeholder="signatory@example.com" onChange={onEmailChange} value={email}></input>
      {successMessage && <p className="text-green-500 mt-2">{successMessage}</p>}
      {errorMessage && <p className="text-red-400 mt-2">{errorMessage}</p>}
      <button type="button" className="text-white bg-blue-500 border-0 py-2 px-6 focus:outline-none hover:bg-blue-600 rounded text-lg mt-2" onClick={invite}>Invite</button>
    </div>
  )
};

const ExternalSolicitorOfferSection = ({ api, state }) => {
  const showInvite = [DevelopmentState.START, DevelopmentState.DOCUMENTS_APPROVED].includes(state);
  return (
    <div>
      {showInvite && <InviteSignatories api={api} />}
      <DefaultOfferSection state={state} />
    </div>
  );
};

const OfferForm = ({ api, development, onModify }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [confirmed, setConfirmed] = useState(false);

  const [offerClicked, setOfferClicked] = useState(false);
  const cancel = () => {
    setOfferClicked(false);
    setErrorMessage('');
    setConfirmed(false);
  };

  const offer = async () => {
    setErrorMessage('');
    if(!confirmed) {
      return;
    }
    try {
      await api.makeOffer(development.id, development.price);
      setOfferClicked(false);
      onModify();
    } catch (e) {
      setErrorMessage(e.message);
    }
  };

  const formattedAmount = formatPrice(development.price);
  const toggleConfirmed = () => setConfirmed(confirmed => !confirmed);
  const offerButtonStyle = confirmed ? 'bg-blue-500 hover:bg-blue-700 cursor-pointer' : 'bg-blue-500 opacity-50 cursor-default';
  const checkboxStyle = confirmed ? '' : 'hidden';

  return (
    <div className="w-full bg-gray-100 text-center rounded-lg my-6 p-5 flex flex-col items-center">
      <h2 className="text-lg font-bold">Make an Offer for Ellwood</h2>
      <p className="mt-2"><span className="font-bold">Price: </span>{formattedAmount}</p>

      <input type="text" className="w-full sm:w-1/2 md:1/3 border border-gray-300 rounded-lg mt-2 h-8 text-center" value={formattedAmount}></input>

      <p class="my-3 text-sm">Offer is subject to contract/contract denied/contract accepted</p>

      {errorMessage && <p className="text-green-500 mt-2">{errorMessage}</p>}
      <button type="button" className="text-white bg-blue-500 border-0 py-2 px-6 focus:outline-none hover:bg-blue-600 rounded text-lg" onClick={setOfferClicked}>Offer</button>

      <Modal isOpen={offerClicked} style={modalStyle}>
        <div className="container flex flex-col items-center justify-center w-96">
          <h1 className="text-2xl font-medium title-font">Make an offer</h1>
          <div className="mt-4 w-full px-6 text-center text-gray-500 font-light">
            <p>Confirm offer of <span className="font-bold">{formattedAmount}?</span></p>

            <div className="mt-6 flex flex-row items-center justify-center w-full">
              <div className="flex flow-row justify-center items-center">
                <div class="bg-white border-2 rounded border-gray-400 w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500" onClick={toggleConfirmed}>
                  <input type="checkbox" class="opacity-0 absolute"/>
                  <svg class={`fill-current w-4 h-4 text-green-500 pointer-events-none ${checkboxStyle}`} viewBox="0 0 20 20"><path d="M0 11l2-2 5 5L18 3l2 2L7 18z"/></svg>
                </div>
                <div className="ml-4 w-56 text-sm text-left">
                  <p>I accept that offer is subject to contract/contract denied/contract accepted</p>
                </div>
              </div>
            </div>

            
          </div>    
          <div className="w-full flex justify-around mt-5">
            <button className="w-full h-12 text-lg bg-gray-600 rounded text-white hover:bg-gray-700 mx-2" onClick={cancel}>Cancel</button>
            <button className={`w-full h-12 text-lg rounded text-white mx-2 ${offerButtonStyle}`} onClick={offer}>Confirm</button>
          </div>
        </div>
      </Modal>
    </div>
  )
};

const SignatoryOfferSection = ({ development, api, onModify }) => {
  switch(development.state) {
    case DevelopmentState.DOCUMENTS_APPROVED:
      return <OfferForm development={development} api={api} onModify={onModify} />
    case DevelopmentState.OFFER_MADE:
      return (
        <div className="w-full bg-gray-100 text-center rounded-lg flex flex-row items-center justify-center p-5">
          <svg className="w-6 h-6 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" /></svg>
          <p className="text ml-4">Your offer has been received. You will be notified by email once it has been approved.</p>
        </div>
      );
    case DevelopmentState.OFFER_ACCEPTED:
      return (
        <div className="w-full bg-gray-100 text-center rounded-lg flex flex-row items-center justify-center p-5">
          <svg className="w-6 h-6 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" /></svg>
          <p className="text ml-4">Your offer has been accepted.</p>
        </div>
      );
    default:
      return (
        <div className="w-full bg-gray-100 text-center rounded-lg flex flex-row items-center justify-center p-5">
          <svg className="w-6 h-6 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
          <p className="text ml-4">You can make an offer on this development once your solicitor has approved the legal documents.</p>
        </div>
      );
  }
};

const OfferSectionForRole = ({ api, development, onModify }) => {
  switch(api.getRole()) {
    case Role.PROP_ADMIN:
      return <PropAdminOfferSection development={development} api={api} onModify={onModify} />;
    case Role.EXTERNAL_SOLICITOR:
      return <ExternalSolicitorOfferSection state={development.state} api={api} />;
    case Role.SIGNATORY:
      return <SignatoryOfferSection development={development} api={api} onModify={onModify} />;
    default:
      return <DefaultOfferSection state={development.state} />;
  }
};

function Offer({ development, api, onModify }) {
  return (
    <div>
      <OfferSectionForRole development={development} api={api} onModify={onModify} />
    </div>
  );
}

export default Offer;