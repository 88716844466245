import React, { useEffect, useState, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useUser, useUserUpdate } from '../contexts/UserContext';
import Loading from '../components/Loading';
import Api from '../domain/Api';

const ListItem = ({ development }) => {
  return (
    <Link to={`/portfolio/${development.id}`}>
        <div className="container w-full rounded-lg flex flex-col border border-2 cursor-pointer flex-grow">
          <img className="rounded-t-lg" src={development.thumbnailUrl} alt="render of ellwood housing development"/>
          <div className="container flex flex-row p-4 justify-between">
            <div className="flex flex-col">
              <p className="font-bold text-xl">{development.name}</p>
              <p className="text-sm">{development.address}</p>
            </div>
            <div className="flex flex-col justify-center items-center">
              <svg className="w-7 h-7" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" /></svg>
              <p className="text-sm">{development.propertyCount}</p>
            </div>
          </div>
        </div>
    </Link>
  );
};

function DevelopmentsPage() {
  const user = useUser();
  const setUser = useUserUpdate();
  const history = useHistory();
  const [developments, setDevelopments] = useState(null);

  const api = useMemo(() => {
    return new Api({
      token: user.token,
      onAuthFail: () => {
        setUser(null);
        history.push('/signin');
      }
    });
  }, [user, setUser, history]);

  useEffect(() => {
    (async () => {
      try {
        const { developments } = await api.listDevelopments();
        setDevelopments(developments);
      } catch (e) {
        // TODO 
      }
    })();
  }, [api]);

  if(!developments) {
    return <Loading />;
  }

  return (
    <section className="text-gray-600 body-font">
        <div className="flex flex-wrap w-full">
          <div className="lg:w-1/2 w-full">
            <h1 className="text-3xl md:text-4xl font-medium title-font mb-2 text-gray-900">Developments</h1>
            <div className="h-1 w-20 bg-blue-500 rounded"></div>
          </div>
      </div>

      <div className="container mx-auto">
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
          {developments.map(d => <ListItem development={d} key={d}/>)}
        </div>
      </div>
    </section>
  );
}

export default DevelopmentsPage;