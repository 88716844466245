import React from 'react';

const Header = () => {
  const cellClass = 'w-36 text-center';
  return (
    <div className="flex flex-row items-center justify-around rounded-lg mb-2 font-bold min-w-min">
      <div className={cellClass}>Type</div>
      <div className={cellClass}>Unit Ref.</div>
      {/* <div className={cellClass}>Unit Ref. Planning</div> */}
      <div className={cellClass}>Description</div>
      <div className={cellClass}>Bedrooms</div>
      <div className={cellClass}>Area m2</div>
      <div className={cellClass}>No. Units</div>
      <div className={cellClass}>Total Area m2</div>
    </div>
  );
};

const Row = ({ type, unitRef, unitRefPlanning, description, persons, area, unitsCount }) => {
  const cellClass = 'w-36 text-center';
  return (
    <div className="h-12 rounded-lg bg-gray-100 w-full flex flex-row items-center justify-around mb-2 hover:bg-gray-200 min-w-min">
      <div className={cellClass}>{type}</div>
      <div className={cellClass}>{unitRef}</div>
      {/* <div className={cellClass}>{unitRefPlanning}</div> */}
      <div className={cellClass}>{description}</div>
      <div className={cellClass}>{persons}</div>
      <div className={cellClass}>{area}</div>
      <div className={cellClass}>{unitsCount}</div>
      <div className={cellClass}>{Math.round(area * unitsCount)}</div>
    </div>
  );
};

const Summary = ({ data }) => {
  const cellClass = 'w-36 text-center font-bold';
  const totalArea = data.reduce((acc, curr) => acc + Math.round(curr.area * curr.unitsCount), 0);
  const totalUnits = data.reduce((acc, curr) => acc + curr.unitsCount, 0);
  return (
    <div className="h-12 rounded-lg bg-gray-200 w-full flex flex-row items-center justify-around mb-2 hover:bg-gray-300 min-w-min">
      <div className={cellClass}>Total</div>
      <div className={cellClass}>{}</div>
      <div className={cellClass}></div>
      <div className={cellClass}></div>
      <div className={cellClass}></div>
      <div className={cellClass}></div>
      <div className={cellClass}>{totalUnits}</div>
      <div className={cellClass}>{totalArea}</div>
    </div>
  );
};

function ScheduleOfAccommodation() {
  const data = [
    { type: 'Type 1', unitRef: 'K', unitRefPlanning: 'K', description: '2 Bed House', persons: '2', area: 75, unitsCount: 22 },
    { type: 'Type 2', unitRef: 'G1, G3', unitRefPlanning: 'G1A, G1C', description: '2 Bed GF', persons: '2', area: 64, unitsCount: 6 },
    { type: 'Type 3', unitRef: 'G2', unitRefPlanning: 'G2B', description: '2 Bed GF', persons: 2, area: 65, unitsCount: 3 },
    { type: 'Type 4', unitRef: 'G4, G5, G6', unitRefPlanning: 'G1D, G1E, G1F', description: '3 Bed Duplex', persons: 3, area: 124, unitsCount: 9 },
    { type: 'Type 5', unitRef: 'G7', unitRefPlanning: 'G7', description: '3 Bed GF', persons: 3, area: 107.5, unitsCount: 1 },
    { type: 'Type 6', unitRef: 'G8', unitRefPlanning: 'G8', description: '2 Bed GF', persons: 2, area: 74.3, unitsCount: 1 },
    { type: 'Type 7', unitRef: 'G9', unitRefPlanning: 'G9', description: '3 Bed Duplex', persons: 3, area: 124, unitsCount: 1 },
    { type: 'Type 8', unitRef: 'G10', unitRefPlanning: 'G10', description: '2 Bed Duplex', persons: 2, area: 102, unitsCount: 1 },
    { type: 'Type 9', unitRef: 'G11', unitRefPlanning: 'G11', description: '3 Bed Duplex', persons: 3, area: 124, unitsCount: 1 },
  ];

  return (
    <div className="w-full overflow-auto">
      <Header />
      <div>
        {data.map(d => <Row {...d} key={d.type} />)}
        <Summary data={data} />
      </div>
    </div>
  );
}

export default ScheduleOfAccommodation;