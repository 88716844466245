import JWT from 'jsonwebtoken';

const USER_KEY = 'user';


class Auth {
  constructor() {
    this.url = `${process.env.REACT_APP_URL}`;
  }

  rehydrateUser() {
    try {
      const asJson = localStorage.getItem(USER_KEY);
      if(!asJson) {
        return null;
      }
      const parsed = JSON.parse(asJson);
      if(!parsed.token) {
        throw new Error('Invalid user');
      }
      const tokenContents = JWT.decode(parsed.token);
      const currentTime = Math.floor(Date.now() / 1000);
      if(currentTime > tokenContents.exp) {
        throw new Error('Token is expired');
      }
      return parsed;
    } catch (e) {
      localStorage.removeItem(USER_KEY);
      return null;
    }
  }

  async makeRequest(endpoint, body = {}) {
    const res = await fetch(`${this.url}${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
    const resBody = await res.json();
    if(res.ok) {
      return [null, resBody];
    }
    return [resBody.message];
  }

  async login(email, password) {
    const [err, res] = await this.makeRequest('/v1/Login', { email, password });
    if(err === 'Forbidden') {
      return ['Invalid email or password'];
    }
    if(err) return [err];
    const userData = {
      ...JWT.decode(res.token),
      token: res.token
    };
    localStorage.setItem(USER_KEY, JSON.stringify(userData));
    return [null, userData];
  }

  async acceptInvite(token, name, password) {
    return this.makeRequest('/v1/AcceptInvite', { token, name, password });
  }

  async resetPassword(token, newPassword) {
    return this.makeRequest('/v1/ResetPassword', { token, newPassword });
  }

  async forgotPassword(email) {
    return this.makeRequest('/v1/ForgotPassword', { email });
  }

  async getTokenDetails(token) {
    return this.makeRequest('/v1/GetTokenDetails', { token });
  }
}

export default Auth;