import React, { useEffect, useState } from 'react';
import Role from '../domain/Role';
import Modal from 'react-modal';
import { Grid } from 'react-css-spinners';
import Button from './util/Button';
import Status from './util/Status';

const modalStyle = {
  content : {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

const User = ({ api, name, email, onModify }) => {
  const [deleteClicked, setDeleteClicked] = useState(false);
  const [status, setStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const cancel = () => {
    setDeleteClicked(false);
    setIsLoading(false);
    setStatus(null);
  };

  const deleteUser = async () => {
    setIsLoading(true);
    setStatus(null);
    const [err] = await api.deleteUser(email);
    if(err) {
      return setStatus({ isError: true, message: err });
    }
    setIsLoading(false);
    setStatus(null);
    onModify();
  };

  return (
    <div className="w-full flex flex-col bg-green-300 text-gray-700 rounded-lg py-2 px-4 mt-2">
      <div className="w-full flex flex-row justify-between items-center">
        <p className="font-bold">{name}</p>
        <svg onClick={setDeleteClicked} className="w-5 h-5 cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
      </div>
      <p className="w-full text-sm text-gray-600 break-all">{email}</p>
      <Modal isOpen={deleteClicked} style={modalStyle}>
        <div className="container flex flex-col items-center justify-center w-96">
          <h1 className="text-2xl font-medium title-font">Delete user {name}?</h1>
          <div className="mt-4 w-full px-6 flex flex-col items-center">
            <p>This action cannot be undone.</p>
            <Status status={status} />
          </div>
          <div className="w-full flex justify-around mt-6">
            <button className="w-full h-12 text-lg bg-gray-600 rounded text-white hover:bg-gray-700 mx-2" onClick={cancel}>Cancel</button>
            <button className="w-full h-12 text-lg bg-red-400 rounded text-white hover:bg-red-500 mx-2" onClick={deleteUser}>Delete</button>
          </div>
        </div>
      </Modal>
    </div>
  );
}



const List = ({ api, users, role, title, onModify }) => {
  const [inviteClicked, setInviteClicked] = useState(false);
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const myUsers = users.filter(u => u.role === role);

  const reset = () => {
    setIsLoading(false);
    setErrorMessage('');
    setInviteClicked(false);
    setEmail('');
  }

  const invite = async () => {
    setErrorMessage('');
    setIsLoading(true);
    try {
      await api.inviteUser(email, role);
    } catch (e) {
      setIsLoading(false);
      return setErrorMessage(e.message);
    }
    reset();
    onModify();
  };

  return (
    <div className="w-full flex flex-col justify-start items-center px-4">
      <h1 className="text-md font-bold text-center">{title}</h1>
      {myUsers.map(u => <User name={u.name} email={u.email} onModify={onModify} key={u.email} api={api} />)}
      <button onClick={() => setInviteClicked(true)} className="w-full flex flex-row items-center justify-center bg-blue-500 rounded-lg mt-2 py-2 hover:bg-blue-600">
        <p className="text-white">Invite {title}</p>
        <svg className="ml-2 w-6 h-6 text-white cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
      </button>
      <Modal isOpen={inviteClicked} style={modalStyle}>
        <div className="container flex flex-col items-center justify-center w-96">
          <h1 className="text-2xl font-medium title-font">Invite {title}</h1>
          <div className="mt-4 w-full px-6 flex flex-col items-center">
            <div className="flex flex-row">
              <label>Email</label>
              <input type="email" className="rounded-lg border border-1 border-gray-200 ml-2" value={email} onChange={e => setEmail(e.target.value)}/>
            </div>
            {
              isLoading &&
              <div className="mt-2">
                <Grid color="#3B82F6" size={60} />
              </div>
            }
            {errorMessage && <p className="text-red-400 mt-2">{errorMessage}</p>}
          </div>
          <div className="w-full flex justify-around mt-6">
            <button className="w-full h-12 text-lg bg-gray-600 rounded text-white hover:bg-gray-700 mx-2" onClick={reset}>Cancel</button>
            <button className="w-full h-12 text-lg bg-green-400 rounded text-white hover:bg-green-500 mx-2" onClick={invite}>Invite</button>
          </div>
        </div>
      </Modal>
    </div>
  );
};


const Invite = ({ api, email, role, onModify }) => {
  const [deleteClicked, setDeleteClicked] = useState(false);
  const [deleteError, setDeleteError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const cancel = () => {
    setDeleteClicked(false);
    setIsLoading(false);
    setDeleteError('');
  };

  const deleteInvite = async () => {
    setIsLoading(true);
    setDeleteError('');
    try {
      await api.deleteInvite(email);
    } catch (e) {
      return setDeleteError(e.message);
    }
    setIsLoading(false);
    setDeleteClicked(false);
    setDeleteError('');
    onModify();
  };

  return (
    <div className="w-full flex flex-col bg-green-300 text-gray-700 rounded-lg py-2 px-4 mt-2">
      <div className="w-full flex flex-row justify-between items-center">
        <p className="w-full font-bold break-all">{email}</p>
        <svg onClick={setDeleteClicked} className="w-5 h-5 cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
      </div>
      <p className="text-sm text-gray-600">{role}</p>
      <Modal isOpen={deleteClicked} style={modalStyle}>
        <div className="container flex flex-col items-center justify-center w-96">
          <h1 className="text-2xl font-medium title-font">Delete invite for {email}?</h1>
          <div className="mt-4 w-full px-6 flex flex-col items-center">
            <p>This action cannot be undone.</p>
            {deleteError && <p className="text-red-400">{deleteError}</p>}
            {
              isLoading &&
              <div className="mt-2">
                <Grid color="#3B82F6" size={60} />
              </div>
            }
          </div>
          <div className="w-full flex justify-around mt-6">
            <button className="w-full h-12 text-lg bg-gray-600 rounded text-white hover:bg-gray-700 mx-2" onClick={cancel}>Cancel</button>
            <button className="w-full h-12 text-lg bg-red-400 rounded text-white hover:bg-red-500 mx-2" onClick={deleteInvite}>Delete</button>
          </div>
        </div>
      </Modal>
    </div>
  );
}



const InviteList = ({ api, invites, onModify }) => {
  return (
    <div className="w-full flex flex-col justify-start">
      <h1 className="text-md font-bold text-center">Pending Invites</h1>
      {invites.map(i => <Invite api={api} email={i.email} role={i.role} onModify={onModify} />)}
    </div>
  );
};


function AdminManagement({ user, api, development, reloadDevelopment }) {
  const [users, setUsers] = useState([]);
  const [invites, setInvites] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const isAdmin = user.role === Role.PROP_ADMIN;

  useEffect(() => {
    if(isAdmin) {
      (async () => {
        const [usersRes, invitesRes] = await Promise.all([
          api.listUsers(),
          api.listInvites()
        ]);
        setUsers(usersRes.users);
        setInvites(invitesRes.invites);
      })();
    }    
  }, [isAdmin, api]);

  if(!isAdmin) {
    return <></>;
  }

  const reload = async () => {
    const [usersRes, invitesRes] = await Promise.all([
      api.listUsers(),
      api.listInvites()
    ]);
    setUsers(usersRes.users);
    setInvites(invitesRes.invites);
  };

  const reset = async () => {
    setIsLoading(true);
    await api.resetDevelopment(development.id);
    reloadDevelopment();
    setIsLoading(false);
  };

  return (
    <div className="w-full bg-green-100 rounded-lg mt-8">
      <div className="w-full flex flex-col">
        <div className="flex flex-row p-2 items-center">
          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20.618 5.984A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016zM12 9v2m0 4h.01"></path></svg>
          <p className="ml-1 text-xs font-bold">Admin Only</p>
          <div className="flex-grow"></div>
          <svg className="w-5 h-5 cursor-pointer" onClick={reload} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"></path></svg>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 px-4 pb-4">
        <List api={api} role={Role.PROP_ADMIN} title='Admin' users={users} onModify={reload} />
        <List api={api} role={Role.PROP_SOLICITOR} title='Prop Solicitor' users={users} onModify={reload} />
        <List api={api} role={Role.EXTERNAL_SOLICITOR} title='External Solicitor' users={users} onModify={reload} />
        <List api={api} role={Role.SIGNATORY} title='Signatory' users={users} onModify={reload} />
        <List api={api} role={Role.GUEST} title='Guest' users={users} onModify={reload} />
        <InviteList api={api} invites={invites} onModify={reload} />
      </div>

      <div className="width-full flex items-center justify-center flex-col mt-4">
        <Button onClick={reset} isLoading={isLoading} colour='red' className="w-64 mb-4">Reset Offer Process</Button>
      </div>
    </div>
  );
}

export default AdminManagement;

