import React, { useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Button from '../components/util/Button';
import { useUser, useUserUpdate } from '../contexts/UserContext';
import Auth from '../domain/Auth';

const inputClassName = 'w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out';

function SignInPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();
  const user = useUser();
  const setUser = useUserUpdate();

  const urlSearchParams = new URLSearchParams(useLocation().search);
  const message = urlSearchParams.get('message');

  const auth = useMemo(() => new Auth(), []);

  useEffect(() => {
    if(user) {
      history.push('/portfolio');
    }
  });

  const signIn = async () => {
    setIsLoading(true);
    setErrorMessage('');
    const [err, userData] = await auth.login(email, password);
    setIsLoading(false);
    if(err) {
      setErrorMessage(err);
      return;
    }
    setUser(userData);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    signIn();
  };
  
  return (
    <div className="container mx-auto mt-16 flex-grow h-full">
      <form className="bg-white flex flex-col mx-auto rounded-xl shadow-md px-8 w-96 pb-12" onSubmit={onSubmit}>
        <svg xmlns="http://www.w3.org/2000/svg" stroke="none" fill="currentColor" className="text-blue-500 h-40 mt-8" viewBox="0 0 200 200">
          <path d="M100,40.24,67.47,70.73a1.63,1.63,0,0,1-1.13.45H47.26a1.66,1.66,0,0,1-1.16-2.84l52.74-52a1.64,1.64,0,0,1,2.32,0l52.74,52a1.66,1.66,0,0,1-1.16,2.84H133.66a1.63,1.63,0,0,1-1.13-.45L100,40.24"/>
          <path d="M154,95.37a57.31,57.31,0,0,0-22.21-21l-2.92-1.58a.83.83,0,0,0-1.22.73v27.68a1.81,1.81,0,0,0,.38,1.1,34.09,34.09,0,0,1,2.87,4.32,37.88,37.88,0,0,1,4.62,18.8,37.89,37.89,0,0,1-4.62,18.81,33.11,33.11,0,0,1-12.73,12.88A36,36,0,0,1,100,161.74a36,36,0,0,1-18.15-4.62,33.11,33.11,0,0,1-12.73-12.88,37.89,37.89,0,0,1-4.62-18.81,37.88,37.88,0,0,1,4.62-18.8A35,35,0,0,1,72.2,102a.79.79,0,0,0,.17-.49V73.62a.83.83,0,0,0-1.18-.75c-1,.47-2,1-3,1.51a56.5,56.5,0,0,0-22.21,21,57.37,57.37,0,0,0-8,30.06,57.38,57.38,0,0,0,8,30.07,56.5,56.5,0,0,0,22.21,21A66.19,66.19,0,0,0,100,184.11a65.74,65.74,0,0,0,31.77-7.62A58.68,58.68,0,0,0,154,95.37Z"/>
          <path d="M121.53,70a72.59,72.59,0,0,0-43,0,.84.84,0,0,0-.58.8V94.75a.82.82,0,0,0,1.28.68c.86-.59,1.74-1.16,2.67-1.68,3.8-2.17,9.66-3.56,14.1-4.2V79.14h8.1V89.55c4.44.64,10.3,2,14.1,4.2.93.52,1.81,1.09,2.67,1.68a.82.82,0,0,0,1.28-.68V70.83A.84.84,0,0,0,121.53,70Z"/>
        </svg>

        <h1 className="text-3xl title-font text-gray-800 text-center">Welcome to Prop</h1>

        {message && <p className="mt-6 text-green-500 font-bold text-center">{message}</p>}
        {errorMessage && <p className="mt-6 text-red-400 font-bold">{errorMessage}</p>}
        <div className="my-4">
          <label htmlFor="email" className="leading-7 text-sm text-gray-600">Email</label>
          <input type="email" id="email" name="email" className={inputClassName} value={email} onChange={e => setEmail(e.target.value)}/>
        </div>
        <div className="mb-8">
          <label htmlFor="password" className="leading-7 text-sm text-gray-600">Password</label>
          <input type="password" id="password" name="password" className={inputClassName} value={password} onChange={e => setPassword(e.target.value)}/>
        </div>
        <Button type='submit' isLoading={isLoading}>Sign in</Button>
        <Link to='/forgotPassword'>
          <p className="text-xs text-gray-500 mt-3 text-center cursor-pointer hover:blue-500">Forgot Password?</p>
        </Link>
      </form>
    </div>
  );
}

export default SignInPage;
