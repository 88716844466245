import React from 'react';

function Hero() {
  return (
    <section className="text-gray-600 body-font">
      <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
          <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Welcome to Ireland’s first technology enabled turn-key housing agreement
            {/* <br className="hidden lg:inline-block"/>readymade gluten */}
          </h1>
          <p className="mb-8 leading-relaxed">Prop uses innovative technology that enables Local authorities and the Irish government to acquire social and affordable housing stock, reviewing planning and legal documents and even digitally sign contracts using Docusign… on any device, anywhere.</p>
          <div className="flex justify-center">
            <a className="inline-flex text-white bg-blue-500 border-0 py-2 px-6 focus:outline-none hover:bg-blue-600 rounded text-lg" href="https://test-louth-site.s3-eu-west-1.amazonaws.com/Prop+Turn-Key.ie+Presentation.pdf" target="_blank" rel="noopener noreferrer">Find out More</a>
            {/* <button className="ml-4 inline-flex text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded text-lg">Button</button> */}
          </div>
        </div>
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
        <img className="z-0 object-cover object-center rounded" alt="hero" src="https://test-louth-site.s3-eu-west-1.amazonaws.com/with-logo+.jpg"/>
        </div>
      </div>
    </section>
  );
}

export default Hero;