import React from 'react';

function SettingsPage() {
  return (
    <div className="container w-full mx-auto pt-32 flex-grow h-full">
      <div className="bg-white flex flex-col w-96 mx-auto">
        <p>Reset Password</p>
        <div className="mb-8">
          <label htmlFor="oldPassword" className="leading-7 text-sm text-gray-600">Old Password</label>
          <input type="password" id="password" name="oldPassword" className="w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
        </div>
        <div className="mb-8">
          <label htmlFor="newPassword" className="leading-7 text-sm text-gray-600">New Password</label>
          <input type="password" id="password" name="newPassword" className="w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
        </div>
        <div className="mb-8">
          <label htmlFor="newPasswordConfirmation" className="leading-7 text-sm text-gray-600">New Password Confirmation</label>
          <input type="password" id="password" name="newPasswordConfirmation" className="w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
        </div>
        <button className="text-white bg-blue-500 border-0 py-2 px-6 focus:outline-none hover:bg-blue-600 rounded text-lg">Change Password</button>
      </div>
    </div>
  );
}

export default SettingsPage;
