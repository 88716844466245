function Pdf({ className }) {
  return (
    <svg className={className} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 512 512" style={{ enableBackground: 'new 0 0 512 512' }} xmlSpace="preserve"
    >
      <path style={{ fill: '#E2E5E7' }} d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"/>
      <path style={{ fill: '#B0B7BD'}} d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"/>
      <polygon style={{ fill: '#CAD1D8' }} points="480,224 384,128 480,128 "/>
    </svg>
  );
}

export default Pdf;