import { Grid } from 'react-css-spinners';

function Loading() {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="mt-20">
        <Grid color="#3B82F6" size={120}></Grid>
      </div>
    </div>
  );
}

export default Loading;