import React, { useContext, useState } from 'react';

const UserContext = React.createContext();
const UserUpdateContext = React.createContext();

export function useUser() {
  return useContext(UserContext);
};

export function useUserUpdate() {
  return useContext(UserUpdateContext);
};

export function UserProvider({ children }) {
  const [user, setUser] = useState(null);

  return (
    <UserContext.Provider value={user}>
      <UserUpdateContext.Provider value={(u) => setUser(u)}>
        {children}
      </UserUpdateContext.Provider>
    </UserContext.Provider>
  );
}