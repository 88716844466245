import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import User from './User';

const SignInButton = () => {
  return (
    <Link to='/signin'>
      <button className="inline-flex items-center bg-gray-100 border-0 py-1 px-3 focus:outline-none hover:bg-gray-200 rounded text-base mt-0">Sign in
        <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-1" viewBox="0 0 24 24">
          <path d="M5 12h14M12 5l7 7-7 7"></path>
        </svg>
      </button>
    </Link>
  );
}

function Navbar() {
  const user = useUser();
  const location = useLocation();
  const isSignInPage = location.pathname === '/signin';

  return (
    <header className="w-screen shadow-md fixed z-50">
      <div className="flex bg-white">
        <Link to='/'>
          <div className="flex mx-12 my-4 title-font items-center">
            <img className="h-10" src="https://static.wixstatic.com/media/080474_b0fb92ff9aff4e83b528acb05ec61ddd~mv2.jpg/v1/fill/w_61,h_83,al_c,q_80,usm_0.66_1.00_0.01/Prop-Marque-Blue.webp" alt="Prop logo"/>
            <span className="ml-3 text-3xl font-extralight">Prop</span>
          </div>
        </Link>

        <div className="flex flex-row justify-center items-center flex-auto">
          <div className="flex flex-row items-start w-full">
            <Link to='/portfolio'>
              <button className="text-gray-500 font-light cursor-pointer hover:text-gray-400">PORTFOLIO</button>
            </Link>

            <Link to='/collaborators'>
              <button className="ml-8 text-gray-500 font-light cursor-pointer hover:text-gray-400">COLLABORATORS</button>
            </Link>
          </div>
        </div>

        <div className="mx-12 my-4 flex items-center">
          {!isSignInPage && user && <User />}
          {!isSignInPage && !user && <SignInButton />}
        </div>
      </div>
    </header>
  );
}

export default Navbar;