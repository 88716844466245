function Status({ status, className='' }) {
  if(!status) {
    return <div></div>;
  }
  const colour = status.isError ? 'text-red-400' : 'test-green-500';
  return (
    <p className={`${className} ${colour}`}>{status.message}</p>
  );
}

export default Status;