import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import Auth from '../domain/Auth';

const inputClassName = 'w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out';

function ResetPasswordPage() {
  const { token } = useParams();
  const [errorMessage, setErrorMessage] = useState(token ? '' : 'Invalid reset link');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const history = useHistory();
  const user = useUser();

  const auth = useMemo(() => new Auth(), []);

  useEffect(() => {
    if(user) {
      history.push('/portfolio');
    }
  });

  const validate = () => {
    if(!password) {
      setErrorMessage('Password is required');
      return false;
    }
    if(password !== confirmPassword) {
      setErrorMessage('Password and Password Confirmation do not match');
      return false;
    }
    return true;
  }

  const resetPassword = async () => {
    setErrorMessage('');
    const [err] = await auth.resetPassword(token, password);
    if(err) {
      return setErrorMessage(err);
    }
    history.push('/signin?message=Password+successfully+reset.+Please+sign+in.');
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const ok = validate();
    if(ok) {
      resetPassword();
    }
  };
  
  return (
    <div className="container w-full mx-auto pt-32 flex-grow h-full">
      <form className="bg-white flex flex-col w-96 mx-auto" onSubmit={onSubmit}>
        <h1 className="text-2xl">Reset Password</h1>
        {errorMessage && <p className="mt-2 text-red-400 font-bold">{errorMessage}</p>}
        <div className="mt-4">
          <label htmlFor="password" className="leading-7 text-sm text-gray-600">Password</label>
          <input type="password" id="password" name="password" className={inputClassName} value={password} onChange={e => setPassword(e.target.value)}/>
        </div>
        <div className="mt-2">
          <label htmlFor="passwordConfirm" className="leading-7 text-sm text-gray-600">Confirm Password</label>
          <input type="password" id="passwordConfirm" name="passwordConfirm" className={inputClassName} value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)}/>
        </div>
        <button type="submit" className="mt-8 text-white bg-blue-500 border-0 py-2 px-6 focus:outline-none hover:bg-blue-600 rounded text-lg">Reset Password</button>
      </form>
    </div>
  );
}

export default ResetPasswordPage;
