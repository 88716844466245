import React, { useEffect, useMemo, useRef, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { MapContainer, TileLayer, Circle } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet/dist/leaflet';
import ProgressIndicator from '../components/ProgressIndicator';
import Offer from '../components/Offer';
import ScheduleOfAccommodation from '../components/ScheduleOfAccomodation';
import { useUser, useUserUpdate } from '../contexts/UserContext';
import { useHistory, useParams } from 'react-router-dom';
import Loading from '../components/Loading';
import AdminManagement from '../components/AdminManagement';
import Api from '../domain/Api';
import DocumentVault from '../components/DocumentVault';

const SectionTitle = ({ ref, text }) => (
  <div ref={ref} className="w-full mt-8 mb-4">
    <h1 className="text-2xl font-medium title-font mb-2 text-gray-900">{text}</h1>
    <div className="h-1 w-20 bg-blue-500 rounded"></div>
  </div>
);

function DevelopmentPage() {
  const { id } = useParams();
  const user = useUser();
  const setUser = useUserUpdate();
  const history = useHistory();
  const [development, setDevelopment] = useState(null);
  const documentSectionRef = useRef(null);
  const bidSectionRef = useRef(null);

  const api = useMemo(() => {
    return new Api({
      token: user.token,
      role: user.role,
      onAuthFail: () => {
        setUser(null);
        history.push('/signin');
      }
    });
  }, [user, setUser, history]);

  useEffect(() => {
    (async () => {
      try {
        const development = await api.getDevelopment(id);
        if(!development) {
          return history.push('/404');
        }
        setDevelopment(development);
      } catch (e) {
        // TODO
      }
    })();
  }, [api, id, history]);

  if(!development) {
    return <Loading />
  }

  const reload = async () => {
    try {
      const development = await api.getDevelopment(id);
      if(!development) {
        return history.push('/404');
      }
      setDevelopment(development);
    } catch (e) {
      // TODO
    }
  };

  const coords = [53.733168, -6.346621];

  return (
    <section className="text-gray-600 body-font">
      <div className="">

        {/* TITLE */}
        <div className="lg:w-1/2 w-full">
          <h1 className="text-3xl md:text-4xl font-medium title-font mb-2 text-gray-900">Ellwood</h1>
          <div className="h-1 w-20 bg-blue-500 rounded"></div>
        </div>

        <AdminManagement user={user} api={api} development={development} reloadDevelopment={reload} />

        <SectionTitle text='Progress' />
        <ProgressIndicator state={development.state} bidSectionRef={bidSectionRef} documentSectionRef={documentSectionRef} />

        {/* PROPERTY FEATURES */}
        <div className="container mx-auto mt-8">
          <div className="h-20 bg-gray-100">

          </div>
        </div>

        {/* CAROUSEL */}
        <Carousel swipeable={true} infiniteLoop={true} emulateTouch={true} useKeyboardArrows={true} showArrows={true} showThumbs={false}>
          <div>
              <img alt="placeholder1" className="relative z-0" src="https://test-louth-site.s3-eu-west-1.amazonaws.com/4.+Ellwood-+Services%2C+amenities%2C+transportation+links.png" />
          </div>
          <div>
              <img alt="placeholder1" className="relative z-0" src="https://test-louth-site.s3-eu-west-1.amazonaws.com/5.+Ellwood+in+the+context+of+Local+Area+Plan+(neighbourhood+centre+2).png" />
          </div>
          <div>
              <img alt="placeholder1" className="relative z-0" src="https://test-louth-site.s3-eu-west-1.amazonaws.com/with-logo+.jpg" />
          </div>
          <div>
              <img alt="placeholder1" className="relative z-0" src="https://test-louth-site.s3-eu-west-1.amazonaws.com/Ballymakenny_characArea01_view02.jpg" />
          </div>
          <div>
              <img alt="placeholder1" className="relative z-0" src="https://test-louth-site.s3-eu-west-1.amazonaws.com/Cluster+H+detail.JPG" />
          </div>
          {/* <div>
              <img alt="placeholder1" className="relative z-0" src="https://test-louth-site.s3-eu-west-1.amazonaws.com/Screenshot+2021-03-04+at+19.52.54.png" />
          </div> */}
          <div>
              <img alt="placeholder1" className="relative z-0" src="https://test-louth-site.s3-eu-west-1.amazonaws.com/354-02-001_-_CLUSTER_H_-_PROPOSED_SITE_LAYOUT_PLAN.png" />
          </div>
          <div>
              <img alt="placeholder1" className="relative z-0" src="https://test-louth-site.s3-eu-west-1.amazonaws.com/TD001+Rev+B+Site+Layout+-+Cluster+H.png" />
          </div>
        </Carousel>

        {/* DESCRIPTION */}
        <SectionTitle text='Development Description' />
        <div>
          <div className="w-full md:w-4/5">
            <p>
              This is a proposal to provide a turnkey housing development in North Drogheda, an area of high demand. The proposed development location is at Ballymakenny Road adjacent to new schools,
              within walking distance of town centre, regional hospital, and multiple employment opportunities.
            </p>

            <p className="mt-4">
              The proposed development has the benefit of a planning permission valid until April 2024 (07/1399 as extended by 18/668) for <span className="font-bold">659 residential units</span>,
              and a <span className="font-bold">neighbourhood centre</span>, including creches, retail and employment spaces and community facilities.
            </p>

            <p className="mt-4">
              The first phase of this allocation will relate to 1 of 17 permitted clusters (Cluster H) in Phase 1 of the subject development, a total of 45 residential units.
              In accordance with Section 96(3) paragraph (b)(i & iva) of the Planning Development Act 2000, it is the applicant’s intention to meet the Part V obligation by way of
              provision of housing by way of acquisition by the Local Authority. The units Prop will provide will consist of <span className="font-bold">33 No. 2 Bed;
              </span> and <span className="font-bold">12 No. 3 Bed dwellings</span>.
            </p>

            <p className="mt-4">
              Construction of the first tranche of the 45 affordable units can be commenced (subject to agreement) in May 2021 with the first units delivered in early 2021.
              The construction programme articulates how we will hand over units in phases of 15 units at a time.
            </p>

            <p className="mt-4">
              It is noted from the briefing document provided that Louth County Council is particularly interested in proposals at Drogheda which has a demand for almost 2000 residential units,
              and we trust that this proposal will be well received in this regard.
            </p>

            <p className="mt-4">
              Our scheme has a good mix of sizes and types, and there are opportunities to modify the internal layouts to accommodate special needs. In complement with this,
              our client has specific social-impact investing objectives that they want to tailor their offering towards catering for the elderly and disabled.
            </p>
          </div>
        </div>

        {/* MAP */}
        <SectionTitle text='Location' />
        <div className="bg-gray-300 rounded-lg overflow-hidden flex items-end justify-start relative">
          
          <div className="w-full z-0">
            <MapContainer center={coords} style={{ height: '32rem' }} zoom={17} scrollWheelZoom={false}>
              <TileLayer
                attribution='© <a href="https://apps.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                url='https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoicHJvcHJvc3MiLCJhIjoiY2ttbjNocW0xMG0xMzJ5bGU3NzdrbTYzcCJ9.qD6zVEtEmxS5pK6V_rD-qg'
              />
              <Circle
                center={coords}
                pathOptions={{ stroke: false, opacity: '1', fillColor: 'red' }}
                radius={40}>
              </Circle>
            </MapContainer>
          </div>
          
          <div className="bg-white absolute flex flex-wrap ml-4 mb-4 py-6 rounded shadow-md">
            <div className="lg:w-1/2 px-6">
              <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">ADDRESS</h2>
              <p className="mt-1">Ellwood,<br/>Ballymakenny&nbsp;Road,<br/>Drogheda,<br/>Co.&nbsp;Louth</p>
            </div>
          </div>
        </div>

        <SectionTitle text='Schedule of Accommodation' />
        <ScheduleOfAccommodation />

        <SectionTitle text='Document Vault' />
        <div ref={documentSectionRef} className="h-0 w-0"></div>
        <DocumentVault api={api} development={development} onModify={reload} />

        <SectionTitle text='Offer' />
        <div ref={bidSectionRef}></div>
        <Offer development={development} api={api} onModify={reload} />

        <div className="mt-36"></div>
      </div>
    </section>
  );
}

export default DevelopmentPage;