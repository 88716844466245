function Zip({ className }) {
  return (
    <svg className={className} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	    viewBox="0 0 512 512" style={{ enableBackground: 'new 0 0 512 512' }} xmlSpace="preserve">
      <path style={{ fill: '#E2E5E7' }} d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"/>
      <path style={{ fill: '#B0B7BD' }} d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"/>
      <polygon style={{ fill: '#CAD1D8' }} points="480,224 384,128 480,128 "/>
      <path style={{ fill: '#84BD5A' }} d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16
        V416z"/>
      <g>
        <path style={{ fill: '#FFFFFF' }} d="M132.64,384c-8.064,0-11.264-7.792-6.656-13.296l45.552-60.512h-37.76
          c-11.12,0-10.224-15.712,0-15.712h51.568c9.712,0,12.528,9.184,5.632,16.624l-43.632,56.656h41.584
          c10.24,0,11.52,16.256-1.008,16.256h-55.28V384z"/>
        <path style={{ fill: '#FFFFFF' }} d="M212.048,303.152c0-10.496,16.896-10.88,16.896,0v73.04c0,10.608-16.896,10.88-16.896,0V303.152z"/>
        <path style={{ fill: '#FFFFFF' }} d="M251.616,303.152c0-4.224,3.328-8.832,8.704-8.832h29.552c16.64,0,31.616,11.136,31.616,32.48
          c0,20.224-14.976,31.488-31.616,31.488h-21.36v16.896c0,5.632-3.584,8.816-8.192,8.816c-4.224,0-8.704-3.184-8.704-8.816
          L251.616,303.152L251.616,303.152z M268.496,310.432v31.872h21.36c8.576,0,15.36-7.568,15.36-15.504
          c0-8.944-6.784-16.368-15.36-16.368H268.496z"/>
      </g>
      <path style={{ fill: '#CAD1D8' }} d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"/>
    </svg>
  );
}

export default Zip;