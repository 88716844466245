import React, { useEffect, useMemo, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useUser, useUserUpdate } from '../contexts/UserContext';
import Loading from './Loading';
import Auth from '../domain/Auth';

const MIN_DELAY = 500;

function AuthRoute(props) {
  const user = useUser();
  const setUser = useUserUpdate();
  const auth = useMemo(() => new Auth(), []);
  
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if(!user) {
      setUser(auth.rehydrateUser());
      setTimeout(() => setLoaded(true), MIN_DELAY);
    }
  }, [user, auth, setUser]);

  if(user) {
    return <Route {...props}/>;
  }

  if(!loaded) {
    return <Loading />
  }

  return <Redirect to={{ pathname: '/signin' }} />;
}

export default AuthRoute;