import React from 'react';

const Title = () => {
  return (
    <div className="flex flex-col items-center mt-4">
      <h1 className="text-3xl md:text-4xl font-medium title-font mb-2 text-gray-900">Collaborators</h1>
      <div className="h-1 w-32 bg-blue-500 rounded mb-4"></div>
    </div>
  );
};

const Subtitle = ({ children }) => {
  return (
    <div className="flex flex-col items-center mt-4">
      <h2 className="text-2xl md:text-2xl font-medium title-font mb-2 text-gray-900">{children}</h2>
      <div className="h-1 w-32 bg-blue-500 rounded mb-4"></div>
    </div> 
  );
}

const Large = ({ imageSrc='', name='', title='', children }) => {
  return (
      <div className="lg:w-4/6 mx-auto mt-12">
        <div className="flex flex-col sm:flex-row mt-10">
          <div className="sm:w-1/3 text-center sm:pr-8 sm:py-8">
            <div className="w-20 h-20 rounded-full inline-flex items-center justify-center bg-gray-200 text-gray-400">
              <img alt="kevin" src="https://test-louth-site.s3-eu-west-1.amazonaws.com/Headshot+no+edit.jpg" className="rounded-full"/>
            </div>

            <div className="flex flex-col items-center text-center justify-center">
              <h2 className="font-medium title-font mt-4 text-gray-900 text-lg">{name}</h2>
              <div className="w-12 h-1 bg-blue-500 rounded mt-2 mb-4"></div>
              <p className="text-base">{title}</p>
            </div>
          </div>
          <div className="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
            {children}
          </div>
        </div>
      </div>
  );
};

const MediumEntry = ({ imageSrc, name, description, children, link }) => {
  return (
    <div className="sm:w-1/2 mb-10 px-4">
      <div className="rounded-lg h-64 overflow-hidden">
        <img alt="content" className="object-cover object-center h-full w-full" src={imageSrc} />
      </div>
      <h2 className="title-font text-2xl font-medium text-gray-900 mt-6">{name}</h2>
      <p className="leading-relaxed mb-2 italic">{description}</p>
      {children}
      <a className="text-blue-500 inline-flex items-center cursor-pointer" target="_blank" rel="noopener noreferrer" href={link}>Website
        <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
          <path d="M5 12h14M12 5l7 7-7 7"></path>
        </svg>
      </a>
    </div>
  );
};

const Medium = () => {
  return (
    <div className="container px-5 mx-auto mt-8">
      <div className="flex flex-wrap -mx-4 text-center">
        <MediumEntry
          imageSrc='https://test-louth-site.s3-eu-west-1.amazonaws.com/Brian-and-Yvonne-Brady.png'
          name='Brady Hughes Consulting'
          description='Engineering, planning consulting and project management'
          link='https://bradyhughes.com/'
        >
           <p className="leading-relaxed text-md mb-2">Brady Hughes Consulting is an award winning Engineering and Planning and Project Management Practice with offices in Drogheda, Co. Louth.</p>
           <p className="leading-relaxed text-md mb-2">The company started life purely as an engineering practice, and was established in 2001 by Brian Hughes and Yvonne Brady, both Chartered Engineers. Yvonne was listed as one of the top 25 engineers of Ireland in 2017, Network Ireland Business Woman of the Year in 2016 and is a European finalist for the Cartier Women’s Initiative in 2014. Since that time the company has grown to encompass a wide and varied range of expertise and experience, with engineers, technicians, and administration staff employed. Our expertise lies in traversing the planning systems and bringing your project to fruition in a timely, cost efficient manner.</p>
           <p className="leading-relaxed text-md mb-2">We aim to deliver excellent service and value to our now very broad range of clients, who include – large residential and commercial property developers, Local Authorities, public bodies, land owners, speculators, and private individuals.</p>
        </MediumEntry>


        <MediumEntry
          imageSrc='https://test-louth-site.s3-eu-west-1.amazonaws.com/unnamed.jpeg'
          name='Darmody Architecture'
          description='Project Architects and Design team lead'
          link='http://www.darmodyarchitecture.com/'
        >
          <p className="leading-relaxed text-md mb-2">Darmody Architecture is an architectural practice with a passion for detail and a wide spectrum of experience, based in Dublin, Ireland. Specialising in architecture, urban planning and interiors, we have a portfolio that spans complex mixed use developments to exquisite private residences. Darmody Architecture was founded in 2002 by Tim Darmody, an architect with over 25 years experience. Since then it has evolved to become one of Ireland's foremost architectural firms. Since its inception the practice has created some of the finest examples of contemporary architecture in Ireland. Currently the firm is busy, working both domestically and abroad with a number of prestigious projects under development in the UK.</p>
        </MediumEntry>

        <MediumEntry 
          name='Kelly O’Callaghan'
          description='Quantity Surveyors'
          link='https://kocqs.com/'
          imageSrc='https://test-louth-site.s3-eu-west-1.amazonaws.com/1557407398191.jpeg'
        >
          <p className="leading-relaxed text-md mb-2">Founded in 1999 by Mark Kelly and Sean O’Callaghan, KOC are celebrating 20 years in business; two decades of supporting our clients in all areas of cost and project management. At KOC we offer our clients a professional approach and efficient service that is tailored to their specific needs. Our core aim is to ensure cost effectiveness at every stage of the construction process and to provide independent, impartial and trustworthy advice to our clients. We’re proud to say that the success of this commitment has been proven time and again through repeat business.</p>
        </MediumEntry>

        <MediumEntry 
          name='Doherty Finegan Kelly'
          description='Engineer, Assigned Certifier, Fire and DAC consultant'
          link='http://dfk.ie'
          imageSrc='https://test-louth-site.s3-eu-west-1.amazonaws.com/IMG_1ABE345C2D44-1.jpeg'
        >
          <p className="leading-relaxed text-md mb-2">Doherty Finegan Kelly (DFK) Consulting Engineers was established in 2003 & specialize in all stages of Civil, Structural, Environmental & Fire Engineering.  With the three offices, DFK practice is well located to provide a high quality service to our client base throughout the country with attention to detail and cost effective solutions being our priority.  Each commission is personally supervised by a director who takes an active role in the evolution of the scheme from concept to completion. The company is registered with the Association of Consulting Engineers of Ireland (ACEI) and all technical staff are members of Engineers Ireland & The Institute of Structural Engineers.</p>
        </MediumEntry>

        <MediumEntry 
          name='Balrath Engineering Consultants'
          description='Mechanical and Electrical Consultants'
          link='http://balrath.ie'
          imageSrc='https://test-louth-site.s3-eu-west-1.amazonaws.com/Balrath-Engineering-8--789x494.jpeg'
        >
          <p className="leading-relaxed text-md mb-2">Balrath Engineering Consultants provides Mechanical, Electrical, Plumbing Design and Consulting services for commercial, industrial and residential developments throughout Ireland, with the ability to assist new building services design, retrofitting and building energy analysis.</p>
        </MediumEntry>

        <MediumEntry 
          name='Griffin Landscape Architects'
          description='Landscape Architects'
          link='https://griffinla.ie/'
          imageSrc='https://test-louth-site.s3-eu-west-1.amazonaws.com/Oisin+G.jpeg'
        >
          <p className="leading-relaxed text-md mb-2">Griffin Landscape Architecture offer a full service in landscape architecture in both the private and public sectors from initial design concept to certification of completed works on site. Our aim is to create outstanding, innovative, environmentally responsible landscapes that give value to clients, enjoyment to the end user and satisfaction to us as professionals. The design element of the practice is supported by a service in project management and site supervision. This enables the practice to work with local authorities, architects, engineers, planners, developers and others to achieve dynamic – yet realizable – landscapes.</p>
          <p className="leading-relaxed text-md mb-2">GLA is a registered practice with ‘The Irish Landscape Institute’ which is affiliated with IFLA (International Federation of Landscape Architects) with our consulting arborist, also an ISA accredited and certified Arborist with the ‘Irish Arboricultural Association’.</p>
        </MediumEntry>
      </div>
    </div>
  );
};

const Quote = () => {
  return (
    <div className="container px-2 py-16 mx-auto">
      <div className="xl:w-1/2 lg:w-3/4 w-full mx-auto text-center">
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="inline-block w-8 h-8 text-gray-400 mb-8" viewBox="0 0 975.036 975.036">
          <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
        </svg>
        <p className="leading-relaxed text-lg">Without the commitment, passion and enthusiasm of&nbsp;all&nbsp;collaborators, the vision for Ireland’s first technology enabled turnkey housing agreement would not have been realised.</p>
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="inline-block w-8 h-8 text-gray-400 mt-8" viewBox="0 0 975.036 975.036">
          <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
        </svg>
      </div>
    </div>
  );
};

const SmallEntry = ({ name, title, imageSrc }) => {
  return (
    <div className="p-4 lg:w-1/4 md:w-1/4">
      <div className="h-full flex flex-col items-center text-center">
        <img alt="headshot" className="flex-shrink-0 rounded-full h-56 object-center object-cover mb-4" src={imageSrc}/>
        <div className="w-full">
          <h2 className="title-font font-medium text-lg text-gray-900">{name}</h2>
          <h3 className="text-gray-500 mb-3">{title}</h3>
        </div>
      </div>
    </div>
  );
};

const Small = () => {
  return (
    <div className="flex flex-wrap mt-4">
      <SmallEntry name='Anita Sands' title='Board Member' description='Placeholder' imageSrc='https://test-louth-site.s3-eu-west-1.amazonaws.com/anita-sands.jpeg' />
      <SmallEntry name='John Brown' title='Board Member' imageSrc='https://test-louth-site.s3-eu-west-1.amazonaws.com/john-brown.jpeg' />
      <SmallEntry name='Luke Gavigan' title='Head of Business Development' imageSrc='https://test-louth-site.s3-eu-west-1.amazonaws.com/1561564797459-removebg-preview.png' />
      <SmallEntry name='Ross Casey' title='Chief Technology Officer' imageSrc='https://test-louth-site.s3-eu-west-1.amazonaws.com/Ross+headshot.jpeg' />
    </div>
  );
};

function TeamPage() {
  return (
    <div>
      <Title />
      <Large imageSrc='https://test-louth-site.s3-eu-west-1.amazonaws.com/Headshot+no+edit.jpg' name='Kevin Sands' title='Chief Executive Officer'>
        <p className="leading-relaxed text-md mb-2">Founded in 2016 by Kevin Sands, Prop has established itself as one of the fastest growing property development and investment companies in Ireland. Through collaborating with long-term partners and project contributors, Prop has forged a distinguished team with an outstanding track record of delivering quality, sustainable and affordable homes.</p>
        <p className="leading-relaxed text-md mb-2">Prop's mission is the sustainable development of community through careful strategic planning matched with holistic building practices. Prop is a technology enabled property development company and, as a principle, works to ensure it is fully aligned with and responsive to local values and needs.</p>
        <p className="leading-relaxed text-md mb-2">With Ellwood we are beginning to realise our vision of creating a truly original mixed use development that will be for sustainable, affordable and the very best in class.</p>
      </Large>

      <Quote />

      <Subtitle>Operations Team</Subtitle>
      <Small />

      <div className="mt-16">
        <Subtitle>Ellwood Portfolio Team</Subtitle>
        <Medium />
      </div>
      
      <div className="h-24"></div>
    </div>
  );
}

export default TeamPage;