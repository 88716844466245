import Footer from './components/Footer';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Modal from 'react-modal';

import { UserProvider } from './contexts/UserContext';

import DevelopmentsPage from './pages/DevelopmentsPage';
import LandingPage from './pages/LandingPage';
import SigninPage from './pages/SigninPage';
import AuthRoute from './components/AuthRoute';
import SignupPage from './pages/SignupPage';
import SettingsPage from './pages/SettingsPage';
import NotFoundPage from './pages/NotFoundPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import DevelopmentPage from './pages/DevelopmentPage';
import CookieBanner from './components/CookieBanner';
import TeamPage from './pages/TeamPage';

Modal.setAppElement('#root');

function App() {
  return (
    <div className="flex flex-col h-screen">
      <UserProvider>
        <Router>
          <Navbar />
          <div className="container mt-24 mx-auto px-8 lg:px-24 xl:px-48 flex-auto">
            <Switch>
                <Route path="/" exact component={LandingPage}/>
                <Route path="/signin" exact component={SigninPage}/>
                <Route path="/signup/:token" exact component={SignupPage}/>
                <Route path="/forgotPassword" exact component={ForgotPasswordPage}/>
                <Route path="/resetPassword/:token" exact component={ResetPasswordPage}/>
                <Route path="/collaborators" exact component={TeamPage}/>


                <AuthRoute path="/settings" exact component={SettingsPage} />
                <AuthRoute path="/portfolio" exact component={DevelopmentsPage} />
                <AuthRoute path="/portfolio/:id" exact component={DevelopmentPage} />

                <Route component={NotFoundPage} />
            </Switch>
          </div>
          <Footer />
        </Router>
      </UserProvider>
      <CookieBanner />
    </div>
  );
}

export default App;
