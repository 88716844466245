import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useUserUpdate } from '../contexts/UserContext';

function User() {
  const setUser = useUserUpdate();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => setMenuOpen(!isMenuOpen);

  // detects click outside of dropdown and closes it
  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if(dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    }

  }, [dropdownRef]);

  const changeable = isMenuOpen
    ? `transition ease-out duration-100 transform opacity-100 scale-100`
    : `transition ease-in duration-75 transform opacity-0 scale-95`

  const fullClassName = `origin-top-right absolute right-12 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 ${changeable}`;

  const signOut = () => {
    setUser(null);
    localStorage.removeItem('user');
  };

  return (
    <div ref={dropdownRef}>
      <svg onClick={toggleMenu} className="w-8 h-8 text-blue-500 hover:text-blue-400 cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
      <div onClick={toggleMenu} className={fullClassName}>
        <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
          <Link to='/settings'>
            <button className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Account settings</button>
          </Link>
          <Link to='/signin'>
            <button type="button" onClick={signOut} className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
              Sign out
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default User;