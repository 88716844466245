import { Link } from 'react-router-dom';

function NotFound() {
  return (
    <div className="flex flex-col items-center mt-24">
      {/* <svg className="w-96 h-96 text-gray-500 animate-bounce" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg> */}
      <svg className="w-96 h-96 text-gray-500 animate-bounce" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" /></svg>
      <p className="text-gray-500">There's nothing here I'm afraid. 
        <Link to='/portfolio'>
          <button className="text-blue-500 pointer-cursor"> Back to safety?</button>
        </Link>
      </p>
    </div>
  );
}

export default NotFound;